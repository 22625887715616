import React, {
  FC, ReactNode, useCallback, useEffect,
} from 'react';
import {
  Button, Form, Input, notification, Spin,
} from 'antd';
import Title from 'antd/es/typography/Title';
import { NotificationPlacement } from 'antd/es/notification';
import { CheckCircleFilled } from '@ant-design/icons';

import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCustomer, updateCustomer } from '../../../store/slices/customerSlice';
import { AppDispatch, RootState } from '../../../store';
import { CustomerType } from '../../../../../common/entityTypes';
import { addSpinnerItem, removeSpinnerItem } from '../../../store/slices/spinnerSlice';
import './CustomerPage.scss';

export const CustomerPage: FC = () => {
  const [form] = Form.useForm();
  const useQuery = useCallback(() => new URLSearchParams(useLocation().search), []);
  const query = useQuery();
  const dispatch = useDispatch<AppDispatch>();
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement: NotificationPlacement, message: string, desc: string, icon: ReactNode) => {
    api.info({
      message,
      description: desc,
      placement,
      icon,
    });
  };

  const customer = useSelector<RootState, CustomerType>((store) => store.customer.customer);
  const spinner = useSelector<RootState, boolean>((store) => store.spinner.spinner);

  useEffect(() => {
    (async () => {
      dispatch(addSpinnerItem('customer_detail'));
      await dispatch(fetchCustomer(query.get('customer') || ''));
      dispatch(removeSpinnerItem('customer_detail'));
    })();
  }, []);

  useEffect(() => {
    form.setFieldsValue(customer);
  }, [customer]);

  const handleOnFinish = async (values: any) => {
    dispatch(addSpinnerItem('customer_detail'));
    await dispatch(updateCustomer({
      customerId: query.get('customer'),
      data: values,
    }));
    const successMsg = 'Customer changed successfully';
    const successDesc = '';
    const successIcon = <CheckCircleFilled style={{ color: 'green' }}/>;
    openNotification('topRight', successMsg, successDesc, successIcon);
    dispatch(removeSpinnerItem('customer_detail'));
  };

  return (
    <Spin spinning={spinner} tip={'please wait ...'}>
      {contextHolder}
      <Form
        name="changeCustomer"
        form={form}
        layout="vertical"
        onFinish={handleOnFinish}
        className={'customer__form'}
      >
        <Title level={2}>
          {customer.customer_name}
        </Title>
        <Form.Item
          label="Address 1"
          name="address1"
          rules={[{ required: true, message: 'Please input customer Address 1!' }]}
          initialValue={customer.address1}
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label="Address 2"
          name="address2"
          initialValue={customer.address2}
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label="City"
          name="city"
          rules={[{ required: true, message: 'Please input customer city!' }]}
          initialValue={customer.city}
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label="State Province"
          name="state_province"
          rules={[{ required: true, message: 'Please input customer State Province!' }]}
          initialValue={customer.state_province}
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label="Zip"
          name="zip"
          rules={[{ required: true, message: 'Please input customer Zip!' }]}
          initialValue={customer.zip}
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label="Country"
          name="country"
          rules={[{ required: true, message: 'Please input customer Country!' }]}
          initialValue={customer.country}
        >
          <Input/>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};
