import * as React from 'react';
import {
  useEffect, useRef, useState,
  useCallback,
  Dispatch, SetStateAction,
} from 'react';
import styled from 'styled-components';
import { MarkerFootprintSvg } from './MarkerFootprintSvg';
import { FootprintType } from '../../../../common/entityTypes';
import { AnswerVisitScheduleType } from '../../../../common/types';
import { PositionType } from '../../../../common/typesEditor';
import { CrossSvg } from './CrossSvg';

const CoverSvgStyle = styled.div`
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;           /* Non-prefixed version, currently
                                  not supported by any browser */
`;

type FloorplanSvgProps = {
  linkUrl: string,
  nameFloorplan?: string,
  listFootprint: FootprintType[],
  selectedFootprint?: FootprintType,
  setKeyDisabledSave: Dispatch<SetStateAction<any | undefined>>,
  setPointScale1?: Dispatch<SetStateAction<PositionType | undefined>>,
  setPointScale2?: Dispatch<SetStateAction<PositionType | undefined>>,
  onChangeImage?: Dispatch<SetStateAction<any | undefined>>,
  followChosenFootprint: boolean,
  highlightFootprint: boolean,
  isMoveMarkers: boolean,
  listVisitSchedule: AnswerVisitScheduleType[],
  modeSetScale?: boolean,
};

const heightEditor = 600;
// const defaultWidthImage = 2000;

type ViewBoxType = {
  x: number,
  y: number,
  width: number,
  height: number,
};

const getStringViewBox = (viewBox: ViewBoxType) => `${viewBox.x} ${viewBox.y} ${viewBox.width} ${viewBox.height}`;

const windowEditor = {
  width: window.innerWidth,
  height: 1000,
};

const getDefaultViewBox = (selectedFootprint?: FootprintType): ViewBoxType => {
  let x = 0;
  let y = 0;
  if (selectedFootprint) {
    x = selectedFootprint.pixel_x ? (selectedFootprint.pixel_x - windowEditor.width / 2) : 0;
    y = selectedFootprint.pixel_y ? (selectedFootprint.pixel_y - windowEditor.height / 2) : 0;
  }
  return {
    x,
    y,
    width: windowEditor.width,
    height: windowEditor.height,
  };
};

const getStartPosition = () => ({
  x: 0,
  y: 0,
});

export const FloorplanSvgEditor: React.FC<FloorplanSvgProps> = (props) => {
  const [viewBox, setViewBox] = useState<ViewBoxType>(getDefaultViewBox());
  const [, setPosition] = useState<PositionType>(getStartPosition());
  const imageSvgRef = useRef(null);
  const svgRef = useRef(null);
  const {
    listFootprint, linkUrl, nameFloorplan, listVisitSchedule, followChosenFootprint,
    selectedFootprint, highlightFootprint, isMoveMarkers, onChangeImage, setKeyDisabledSave,
    modeSetScale, setPointScale1, setPointScale2,
  } = props;

  useEffect(() => {
    if (followChosenFootprint) {
      setViewBox(getDefaultViewBox(selectedFootprint));
    }
  }, [followChosenFootprint, selectedFootprint]);

  useEffect(() => {
    const handleResize = () => {
      // setViewBox({
      //   x: position.x,
      //   y: position.y,
      //   width: viewBox.width,
      //   height: viewBox.height,
      // });
    };
    window.addEventListener('resize', handleResize);
  });

  const onClickImage = useCallback(async () => {
    if (onChangeImage) await onChangeImage(true);
  }, []);

  const getScale = useCallback(() => {
    const newScale = imageSvgRef.current ? (imageSvgRef.current as any).getClientRects()[0].width / 2000 : 1;
    return newScale;
  }, []);

  const handleMouseMove = useRef((e: any) => {
    setPosition((oldPosition: PositionType) => {
      const xDiff = oldPosition.coords ? oldPosition.coords.x - e.pageX : 0;
      const yDiff = oldPosition.coords ? oldPosition.coords.y - e.pageY : 0;
      const newX = oldPosition.x - xDiff / getScale();
      const newY = oldPosition.y - yDiff / getScale();

      if (e.ctrlKey) {
        setViewBox((viewBox1) => ({
          x: viewBox1.x,
          y: viewBox1.y,
          width: viewBox1.width,
          height: Math.abs(+viewBox1.height + yDiff),
        }));
      } else {
        setViewBox((viewBox1) => ({
          x: newX * -1,
          y: newY * -1,
          width: viewBox1.width,
          height: viewBox1.height,
        }));
      }

      return {
        x: newX,
        y: newY,
        coords: {
          x: e.pageX,
          y: e.pageY,
        },
      };
    });
  });

  const handleMouseDown = (e: any) => {
    const { pageX, pageY } = e;
    setPosition((position1: any) => ({
      ...position1,
      coords: {
        x: pageX,
        y: pageY,
      },
    }));

    document.addEventListener('mousemove', handleMouseMove.current);
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove.current);
    setPosition((position1: any) => ({ ...position1, coords: {} }));
    setKeyDisabledSave(false);
  };

  return <>
    <CoverSvgStyle>
      <svg xmlns="http://www.w3.org/2000/svg"
           width={'100%'}
           height={heightEditor}
           values={nameFloorplan}
           viewBox={getStringViewBox(viewBox)}
           onMouseDown={handleMouseDown}
           onMouseUp={handleMouseUp}
           className={'svg_floorplan'}
           ref={svgRef}
      >
        <image ref={imageSvgRef} x="0" y="0" width="2000" xlinkHref={linkUrl} onClick={onClickImage}/>
        {
          !modeSetScale
            ? listFootprint.map((elem: FootprintType) => <MarkerFootprintSvg
              key={elem.partner_footprint_id}
              etalonScale={imageSvgRef}
              footprint={elem}
              selected={selectedFootprint && selectedFootprint.footprint_id === elem.footprint_id}
              highlight={highlightFootprint}
              defaultPosition={{ x: viewBox.x + viewBox.width / 2, y: viewBox.y + viewBox.height / 2 }}
              showForVisitSchedule={(listVisitSchedule && (listVisitSchedule as (AnswerVisitScheduleType & { footprint_id: number })[]).filter((subElem) => (subElem.visitLineId === elem.container_footprint_name
                || subElem.footprint_id === elem.footprint_id) && subElem.doService).length) as any as boolean}
              isMoveMarkers={isMoveMarkers}
          />)
            : [
                <CrossSvg key={'cross_1'} etalonScale={imageSvgRef} defaultPosition={{ x: 100, y: 100 }} setPointScale={setPointScale1} />,
                <CrossSvg key={'cross_2'} etalonScale={imageSvgRef} defaultPosition={{ x: 200, y: 100 }} setPointScale={setPointScale2} />,
            ]
        }
      </svg>
    </CoverSvgStyle>
  </>;
};
