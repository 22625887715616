import React, {
  useCallback,
  useState,
} from 'react';
import {
  Checkbox, Col,
  Form, Row, Select, Table,
} from 'antd';
import '../styles/LocationMainPage.scss';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { RecurrenceType } from '../../../../../common/types';
import { ScheduleSettingType } from '../../../../../common/entityTypes';
import { daysWeek, DEFAULT_RECURRENCE, recurrence } from '../../../common/web_consts';

export const TabStatic = () => {
  const scheduleSetting = useSelector<RootState, ScheduleSettingType | null>((store) => store.location.locationScheduleSetting);

  const [countWeek, setCountWeek] = useState(scheduleSetting ? scheduleSetting.recurrence : DEFAULT_RECURRENCE);

  const getDataSource = useCallback((numberWeek: number) => (
    [
      {
        key: `1_${numberWeek}`,
      },
    ]
  ), []);

  const getColumns = useCallback((numberWeek: number) => daysWeek.map((dayWeek) => ({
    title: dayWeek,
    dataIndex: dayWeek,
    width: 100,
    key: dayWeek,
    render: () => {
      const week = (scheduleSetting as any)[`week_${numberWeek}`];
      const checkedDay = scheduleSetting && week ? week[dayWeek.toLowerCase()] : false;
      return <Form.Item
            name={`${numberWeek}_${dayWeek}`}
            valuePropName="checked"
        >
            <Checkbox checked={checkedDay} onChange={() => {}}></Checkbox>
        </Form.Item>;
    },
  })), []);

  const getTablesWeek = useCallback((subCountWeek: number) => {
    console.log('getTablesWeek', R.range(1, subCountWeek + 1));
    return R.range(1, subCountWeek + 1).map((numberWeek) => {
      if (subCountWeek === 1) {
        return <Table
                    key={numberWeek}
                    columns={getColumns(numberWeek)}
                    dataSource={getDataSource(numberWeek)}
                    pagination={false}
                    onChange={() => {}}
                />;
      }

      return <Row key={numberWeek}>
                <Col span={6}>
                    week
                </Col>
                <Col span={18}>
                    <Table
                        key={numberWeek}
                        columns={getColumns(numberWeek)}
                        dataSource={getDataSource(numberWeek)}
                        pagination={false}
                        onChange={() => {}}
                    />
                </Col>
            </Row>;
    });
  }, [countWeek]);

  return (
    <>
        <Form.Item
            label="Recurrence"
            name={'recurrence'}
            rules={[
              { required: true, message: 'Please choose value!' },
            ]}
        >
            <Select
                style={{ width: 120 }}
                onChange={(e: RecurrenceType) => {
                  setCountWeek(e);
                }}
                options={ recurrence.map((numberWeek) => ({
                  value: numberWeek,
                  label: numberWeek,
                }))}
            />
        </Form.Item>
        {
            getTablesWeek(+countWeek.toString().split(' ')[0])
        }
    </>
  );
};
