import React, { useCallback, useEffect, useState } from 'react';
import {
  Space, Spin,
  Table, Typography,
} from 'antd';
import { Translate } from 'i18n-next-tools';
import { DateTime } from 'luxon';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Signal } from '../components/Signal';
import { DEFAULT_VALUE, PAGINATION_TABLE } from '../consts';
import { TableSearch } from '../components/TableSearch';
import { AppDispatch, RootState } from '../store';
import { fetchLocationFootprint } from '../store/slices/locationSlice';
import { LocationFootprintDeviceType } from '../../../common/entityTypes';
import { handlePageSize } from '../common';
import { Battery } from '../components/Battery';

const { Title } = Typography;

const getColumns = (paramsColumn: { columnsSearch: { [key: string]: object | undefined } }): ColumnsType<LocationFootprintDeviceType> => [
  {
    title: 'Warehouse',
    dataIndex: 'warehouse_name',
    key: 'warehouse_name',
    ...paramsColumn.columnsSearch.columnSearchWarehouse,
  },
  {
    title: 'Customer',
    dataIndex: 'customer_name',
    key: 'customer_name',
    sorter: (a: any, b: any) => (a.customer_name > b.customer_name ? 1 : -1),
    ...paramsColumn.columnsSearch.columnSearchCustomer,
  },
  {
    title: 'City',
    dataIndex: 'city',
    key: 'city',
    sorter: (a: any, b: any) => (a.city > b.city ? 1 : -1),
    ...paramsColumn.columnsSearch.columnSearchCity,
  },
  {
    title: 'State',
    dataIndex: 'state_province',
    key: 'state_province',
    sorter: (a: any, b: any) => (a.state_province > b.state_province ? 1 : -1),
    ...paramsColumn.columnsSearch.columnSearchState,
  },
  {
    title: 'Address',
    dataIndex: 'location_name',
    key: 'location_name',
    sorter: (a: any, b: any) => (a.location_name > b.location_name ? 1 : -1),
    render: (value: string, row: any) => `${value} ${row.address1 || ''} ${row.address2 || ''}`,
    ...paramsColumn.columnsSearch.columnSearchAddress,
  },
  {
    title: 'Footprint',
    dataIndex: 'partner_footprint_id',
    key: 'partner_footprint_id',
    sorter: (a: any, b: any) => (a.partner_footprint_id > b.partner_footprint_id ? 1 : -1),
    ...paramsColumn.columnsSearch.columnSearchPlace,
    render: (partner_footprint_id: number, row: LocationFootprintDeviceType) => {
      const params = [
        `footprint=${row.footprint_id}`,
        'rowlimit=100',
      ];
      const link = `/footprints/detail?${params.join('&')}`;
      return <Link className="table__link" to={link}>{partner_footprint_id}</Link>;
    },
  },
  {
    title: 'Barcode',
    dataIndex: 'barcode',
    key: 'barcode',
    render: (value: string) => (value || DEFAULT_VALUE),
  },
  {
    title: 'Device Name',
    dataIndex: 'device_number',
    key: 'device_number',
    ...paramsColumn.columnsSearch.columnSearchDeviceNumber,
  },
  {
    title: 'Last Report',
    dataIndex: 'report_datetime',
    key: 'report_datetime',
    defaultSortOrder: 'descend',
    sorter: (a: any, b: any) => {
      const time1 = a.report_datetime ? DateTime.fromISO(a.report_datetime).toMillis() : 0;
      const time2 = b.report_datetime ? DateTime.fromISO(b.report_datetime).toMillis() : 0;
      return (time1 > time2 ? 1 : -1);
    },
    render: (value: string) => (value ? DateTime.fromISO(value).toUTC().toFormat('y-LL-dd tt') : DEFAULT_VALUE),
  },
  {
    title: 'Fill %',
    dataIndex: 'fill_percent',
    key: 'fill_percent',
    sorter: (a: any, b: any) => (+a.fill_percent > +b.fill_percent ? 1 : -1),
    render: (value: number) => ((value >= 0) ? `${Math.round(value)}%` : DEFAULT_VALUE),
  },
  {
    title: 'Last Service',
    dataIndex: 'latest_service_date',
    key: 'last_service_date_1',
    sorter: (a: any, b: any) => {
      const time1 = a.latest_service_date ? DateTime.fromISO(a.latest_service_date).toMillis() : 0;
      const time2 = b.latest_service_date ? DateTime.fromISO(b.latest_service_date).toMillis() : 0;
      return (time1 > time2 ? 1 : -1);
    },
    render: (value: string) => (value ? `${DateTime.fromISO(value).toUTC().toFormat('y-LL-dd tt')}` : DEFAULT_VALUE),
  },
  {
    title: 'Days Since Service',
    dataIndex: 'latest_service_date',
    key: 'last_service_date_2',
    sorter: (a: any, b: any) => (
      Math.round(DateTime.now().diff(DateTime.fromISO(a.latest_service_date), 'days').days)
      > Math.round(DateTime.now().diff(DateTime.fromISO(b.latest_service_date), 'days').days) ? 1 : -1),
    render: (value: string) => (value ? `${Math.round(DateTime.now().diff(DateTime.fromISO(value), 'days').days)}` : DEFAULT_VALUE),
  },
  {
    title: 'Signal',
    dataIndex: 'signal_rsrp',
    key: 'signal_rsrp',
    render: (value: number) => (value ? <Signal rssi={value}/> : DEFAULT_VALUE),
  },
  {
    title: 'Battery',
    dataIndex: 'battery',
    key: 'battery',
    render: (value: number) => (value ? <Battery value={value}/> : DEFAULT_VALUE),
  },
];

export const FootprintListPage: React.FC = () => {
  const [paginationTable, setPaginationTable] = useState<TablePaginationConfig>({
    ...PAGINATION_TABLE,
    pageSize: handlePageSize({ place: 'locations_footprints' }),
  });
  const [columnSearchPlace, setColumnSearchPlace] = useState<object>();
  const [columnSearchDeviceNumber, setColumnSearchDeviceNumber] = useState<object>();
  const [columnSearchWarehouse, setColumnSearchWarehouse] = useState<object>();
  const [columnSearchCustomer, setColumnSearchCustomer] = useState<object>();
  const [columnSearchAddress, setColumnSearchAddress] = useState<object>();
  const [columnSearchCity, setColumnSearchCity] = useState<object>();
  const [columnSearchState, setColumnSearchState] = useState<object>();

  // const history = useHistory();

  const columns: ColumnsType<LocationFootprintDeviceType> = getColumns({
    columnsSearch: {
      columnSearchPlace,
      columnSearchDeviceNumber,
      columnSearchWarehouse,
      columnSearchCustomer,
      columnSearchAddress,
      columnSearchCity,
      columnSearchState,
    },
  });

  const dispatch = useDispatch<AppDispatch>();
  const locationFootprintList = useSelector<RootState, LocationFootprintDeviceType[]>((store) => store.location.locationFootprintList);
  const locationLoader = useSelector<RootState, boolean>((store) => store.location.locationLoader);

  useEffect(() => {
    if (!locationFootprintList.length) dispatch(fetchLocationFootprint());
  }, []);

  const handleTableChange = useCallback((pagination) => {
    handlePageSize({ place: 'locations_footprints', pageSize: pagination.pageSize });
    setPaginationTable(pagination);
  }, []);

  const getColumnSearchProps = useCallback((callback: (dataIndex: any) => {}) => {
    setColumnSearchPlace(callback('partner_footprint_id'));
    setColumnSearchDeviceNumber(callback('device_number'));
    setColumnSearchWarehouse(callback('warehouse_name'));
    setColumnSearchCustomer(callback('customer_name'));
    setColumnSearchAddress(callback('location_name'));
    setColumnSearchCity(callback('city'));
    setColumnSearchState(callback('state_province'));
  }, []);

  const t = Translate('LocationListPage');

  return (
    <>
      <TableSearch columnSearchProps={getColumnSearchProps}/>
      <Space direction={'horizontal'} align={'center'} style={{ justifyContent: 'space-between', width: '100%' }}>
        <Title level={3}>{t.k1('Footprints')}</Title>
      </Space>
      <Spin spinning={locationLoader} tip={'please wait ...'}>
        <Table
          columns={columns}
          className={'click_row'}
          dataSource={locationFootprintList}
          pagination={paginationTable}
          onChange={handleTableChange}
        />
      </Spin>
    </>
  );
};
