import * as React from 'react';
import { Tooltip } from 'antd';
import { SIGNAL } from '../constsSIGNAL';

type SignalProps = {
  rssi?: number,
  isUnitWifi?: boolean,
  isClop?: boolean
};

export const Signal: React.FC<SignalProps> = ({ rssi, isUnitWifi, isClop }) => {
  if (!rssi || rssi < -1000) return <span> </span>;

  const newRssi = -rssi;

  const iconName = () => {
    let icon = SIGNAL.ICON_POOR;

    if (newRssi <= SIGNAL.SIGNAL_80) {
      icon = SIGNAL.ICON_EXCELLENT;
    } else if (newRssi > SIGNAL.SIGNAL_80 && newRssi <= SIGNAL.SIGNAL_90) {
      icon = SIGNAL.ICON_GOOD;
    } else if (newRssi > SIGNAL.SIGNAL_90 && newRssi <= SIGNAL.SIGNAL_100) {
      icon = SIGNAL.ICON_POOR;
    } else if (newRssi > SIGNAL.SIGNAL_100) {
      icon = SIGNAL.ICON_FAIR;
    } else {
      icon = SIGNAL.ICON_ZERO;
    }
    return icon;
  };

  const getTitle = () => {
    let title = isUnitWifi ? 'wifi rssi' : 'cell rssi';

    if (isClop) {
      title += `: ${rssi}`;
    }
    return title;
  };

  return <Tooltip title={rssi}>
    <span title={getTitle()} className={`signalStrengthIcon ${iconName()}`}> </span>
  </Tooltip>;
};
