import React, {
} from 'react';
import {
  Form, InputNumber,
} from 'antd';
import '../styles/LocationMainPage.scss';

export const TabInterval = () => {
  console.log();
  return (
    <>
        <Form.Item
            label="Days between service"
            name={'days_between_service'}
        >
            <InputNumber max={100} min={0} />
        </Form.Item>
        <Form.Item
            label="Flexibility"
            name={'flexibility'}
        >
            <InputNumber max={10} min={0} />
        </Form.Item>
    </>
  );
};
