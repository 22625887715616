import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './styles/App.css';
import './styles/menu.css';
import './styles/transfer.css';
import './styles/datepicker.css';
import './styles/collapse.css';
import './styles/button.css';
import './styles/spin.css';
import './styles/input.css';
import './styles/checkbox.css';
import './styles/select.css';
import './styles/tabs.css';
import './styles/table.css';
import './styles/fileUpload.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Result } from 'antd';
import { LoginPage } from './pages/LoginPage';
import { API } from './constsAPI';
import { SubPageTemplate } from './components/SubPageTemplate';
import { MenuItem, MenuSubItem } from './components/MenuSystem';
import { getMenu } from './dataSubMenu';
import { DataCommonProvider, useDataCommon } from './DataCommonProvider';
import { RouteAuth } from './components/RouteAuth';
import { PageTemplate } from './components/PageTemplate';
import { ChangePasswordPage } from './pages/ChangePasswordPage';
import { SetNewPasswordPage } from './pages/SetNewPasswordPage';
import { ResetPasswordPage } from './pages/ResetPasswordPage';
import { DeviceListPage } from './pages/DeviceListPage';
import { ReportListPage } from './pages/ReportListPage';
import { FootprintListPage } from './pages/FootprintListPage';
import { FootprintDetailPage } from './pages/FootprintDetailPage';
import { FloorplanPage } from './pages/FloorplanPage';
import { DeviceDetailPage } from './pages/DeviceDetailPage';
import { OperatorType } from '../../common/entityTypes';
import { UsersPage } from './pages/UsersPage';
import { SchedulePage } from './pages/SchedulePage';
import { CustomersPage } from './pages/CustomersPage';
import { RegionsPage } from './pages/RegionsPage';
import { CustomerPage } from './pages/CustomerPage';
import { AddCustomer } from './pages/AddCustomer';
import { ReportRawListPage } from './pages/ReportRawListPage';
import { LocationDetail, LocationPage } from './pages/LocationPage';
import { ERequestUrl } from './store/config';
import { ShipDevicePage } from './pages/ShipDevicePage';
import { MoveDevice } from './pages/MoveDevice';
import { Warehouse } from './pages/WarehousePage';
import { NotFoundPage } from './pages/NotFound';
import { paths } from './common';
import { RouteSchedulePage } from './pages/RouteSchedulePage';
import { AppDispatch, RootState } from './store';
import { setSliceOperator } from './store/slices/operatorSlice';
import { AlertPage } from './pages/AlertPage';
import { RouteDetailPage, RoutePage } from './pages/RoutePage';
import { setDomain } from './store/slices/appSlice';
import { IConfigInterface } from './domainConfig';

const contentByRoute: any = {
  [`${paths.ALERTS}${paths.COMBINE}`]: () => <AlertPage
    filter={{ no_contact: true, battery: true, need_service: true }}/>,
  [`${paths.ALERTS}${paths.NEED_SERVICE}`]: () => <AlertPage filter={{ need_service: true }}/>,
  [`${paths.ALERTS}${paths.BATTERY}`]: () => <AlertPage filter={{ battery: true }}/>,
  [`${paths.ALERTS}${paths.NO_CONTACT}`]: () => <AlertPage filter={{ no_contact: true }}/>,
  '/alerts/dispatch': () => '/alerts/dispatch',
  '/alerts/new_ticket': () => '/alerts/new_ticket',
  '/alerts/machine': () => '/alerts/machine',
  '/alerts/dex': () => '/alerts/dex',
  '/alerts/inventory': () => '/alerts/inventory',
  '/alerts/sales': () => '/alerts/sales',
  '/alerts/bill_coin': () => '/alerts/bill_coin',
  '/alerts/coil': () => '/alerts/coil',
  '/alerts/signal': () => '/alerts/signal',
  '/alerts/cashless': () => '/alerts/cashless',
  '/alerts/pricing': () => '/alerts/pricing',
  [`${paths.SCHEDULE}${paths.ROUTE_SCHEDULE}`]: () => <RouteSchedulePage/>,
  [`${paths.SCHEDULE}${paths.SCHEDULE}`]: () => <SchedulePage/>,
  [`${paths.TECH}${paths.REPORTS_RAW}`]: () => <ReportRawListPage/>,
  [`${paths.TECH}${paths.REPORTS}`]: () => <ReportListPage/>,
  [`${paths.SETUP}${paths.CUSTOMERS}`]: () => <CustomersPage/>,
  [`${paths.SETUP}${paths.LOCATIONS}`]: () => <LocationPage/>,
  [`${paths.SETUP}${paths.SHIP_DEVICE}`]: () => <ShipDevicePage/>,
  [`${paths.SETUP}${paths.MOVE_DEVICE}`]: () => <MoveDevice/>,
  [`${paths.SETUP}${paths.WAREHOUSE}`]: () => <Warehouse/>,
  [`${paths.SETUP}${paths.FLOORPLAN}`]: () => <FloorplanPage/>,
  [`${paths.SETUP}${paths.REGIONS}`]: () => <RegionsPage/>,
  [`${paths.SETUP}${paths.USERS}`]: () => <UsersPage/>,
  [`${paths.SETUP}${paths.ROUTES}`]: () => <RoutePage/>,
  [paths.FOOTPRINTS]: () => <FootprintListPage/>,
  //  [paths.SETUP]: () => <SetupPage/>,
  [paths.DEVICES]: () => <DeviceListPage/>,
  '/cash/ticket_list': () => '/cash/ticket_list',
  '/cash/ticket_details': () => '/cash/ticket_details',
  '/cash/history': () => '/cash/history',
  '/cash/input': () => '/cash/input',
  '/cash/import': () => '/cash/import',
  '/cash/bill_coin_counter': () => '/cash/bill_coin_counter',
};

function App() {
  const dataCommon = useDataCommon();
  const { i18n } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const defaultOperator = localStorage.getItem('operator_code') ? {
    operator_code: localStorage.getItem('operator_code'),
    operator_name: localStorage.getItem('operator_name'),
    operator_id: localStorage.getItem('operator_id'),
  } : undefined;

  const [language, setLanguage] = useState(localStorage.getItem('language') || 'en');

  const [operator, setOperator] = useState<OperatorType>(defaultOperator as any);
  const appConfig = useSelector<RootState, IConfigInterface | null>((store) => store.app.config);

  axios.interceptors.request.use(async (config) => {
    if (config.url && (config.url.includes(API.URL_AURORA_DB) || config.url.includes(ERequestUrl.BASE_CRUD))) {
      if (!config.url.includes('operator_list')) {
        const newConfig = config;
        if (operator && !newConfig.url!.includes('operator=')) {
          const pathParts = window.location.pathname.split('/');
          const page = pathParts[pathParts.length - 1];

          if (!newConfig.url!.includes('?')) {
            newConfig.url += `?operator=${operator.operator_id}&page=${page}`;
          } else {
            newConfig.url += `&operator=${operator.operator_id}&page=${page}`;
          }
        }
      }
      const headers = config.headers as any;
      headers.Authorization = `Bearer ${localStorage.getItem('IdToken')}`;
    }

    if (config.url && (config.url.includes(API.URL_LOGIN) || config.url.includes(API.URL_AURORA_DB))
      && !config.url.includes('change_password')) return config;

    if (config.url && config.url.includes(API.URL_REST_API)) {
      const headers = config.headers as any;
      headers.Authorization = `Bearer ${localStorage.getItem('IdToken')}`;
      headers['x-api-key'] = API.API_KEY;
      return config;
    }

    const headers = config.headers as any;
    headers.Authorization = `Bearer ${localStorage.getItem('IdToken')}`;

    return config;
  }, () => {
  });

  const getContentByRoute = (path: string) => contentByRoute[path];

  const useComponentWillMount = (cb: any) => {
    const willMount = useRef(true);

    if (willMount.current) cb();

    willMount.current = false;
  };

  const defineDomain = () => {
    if (window.location.hostname.toLowerCase().includes('shredbinbot')) {
      return 'shred';
    }

    if (window.location.hostname.toLowerCase().includes('demodomainbinbot')) {
      return 'demodomain';
    }

    if (window.location.hostname.toLowerCase().includes('localhost')) {
      return 'shred';
    }

    return localStorage.getItem('domain') || 'shred';
  };

  useEffect(() => {
    dispatch(setDomain(defineDomain()));
  }, []);

  useEffect(() => {
    dataCommon.language = language;
    localStorage.setItem('language', language);
    console.log('change language', language);
    i18n.changeLanguage(language);
  }, [language]);

  useEffect(() => {
    if (appConfig) {
      document.documentElement.style.setProperty('--primary-color', appConfig?.themeColor);
    }
  }, [appConfig]);

  useEffect(() => {
    console.log('change operator', operator);
    if (operator) {
      dispatch(setSliceOperator(operator));
      localStorage.setItem('operator_id', operator.operator_id as any as string);
      localStorage.setItem('operator_name', operator.operator_name);
      localStorage.setItem('operator_code', operator.operator_code);
    } else {
      localStorage.setItem('operator_id', '');
      localStorage.setItem('operator_name', '');
      localStorage.setItem('operator_code', '');
    }
  }, [operator]);

  useComponentWillMount(() => {
    dataCommon.username = localStorage.getItem('username') || '';
    dataCommon.language = language;
    dataCommon.setLanguage = setLanguage;
    dataCommon.operator = operator!;
    dataCommon.setOperator = setOperator;
    dispatch(setSliceOperator(operator));
  });

  const renderRoute = (item: MenuSubItem, subItem?: MenuItem) => {
    const path = `${item.path}${subItem ? subItem.path : ''}`;
    return <RouteAuth key={`route_${path}`} path={path}>
      <SubPageTemplate subItemsMenu={item.items} parentPath={item.path} path={subItem ? subItem.path : ''}>
        {getContentByRoute(path) ? getContentByRoute(path)() : 'content not found ...'}
      </SubPageTemplate>
    </RouteAuth>;
  };

  const renderMenuRoute = () => (getMenu().map((item: MenuSubItem) => {
    if (!item.items.length) {
      return renderRoute(item);
    }

    return (item.items.map((subItem) => renderRoute(item, subItem)));
  }));

  return (
    <>
      <DataCommonProvider>
        <Router>
          <Switch>
            <Route path={paths.LOGIN}>
              <LoginPage/>
            </Route>
            <Route path={paths.SET_NEW_PASSWORD}>
              <SetNewPasswordPage/>
            </Route>
            <Route path={paths.RESET_PASSWORD}>
              <ResetPasswordPage/>
            </Route>
            <RouteAuth path={paths.FOOTPRINTS_DETAIL}>
              <SubPageTemplate subItemsMenu={[]} parentPath={'/footprints'} path={''}>
                <FootprintDetailPage/>
              </SubPageTemplate>
            </RouteAuth>
            <RouteAuth path={'/setup/location/detail'}>
              <SubPageTemplate subItemsMenu={[]} parentPath={'/setup/locations'} path={''}>
                <LocationDetail/>
              </SubPageTemplate>
            </RouteAuth>
            <RouteAuth path={paths.CUSTOMER_DETAIL}>
              <SubPageTemplate subItemsMenu={[]} parentPath={'/customer'} path={''}>
                <CustomerPage/>
              </SubPageTemplate>
            </RouteAuth>
            <RouteAuth path={paths.ROUTE_DETAIL}>
              <SubPageTemplate subItemsMenu={[]} parentPath={'/route'} path={''}>
                <RouteDetailPage/>
              </SubPageTemplate>
            </RouteAuth>
            <RouteAuth path={paths.CUSTOMER_ADD}>
              <SubPageTemplate subItemsMenu={[]} parentPath={'/customer'} path={''}>
                <AddCustomer/>
              </SubPageTemplate>
            </RouteAuth>
            <RouteAuth path={paths.DEVICE_DETAIL}>
              <SubPageTemplate subItemsMenu={[]} parentPath={'/device'} path={''}>
                <DeviceDetailPage/>
              </SubPageTemplate>
            </RouteAuth>
            {
              renderMenuRoute()
            }
            <RouteAuth path={paths.CHANGE_PASSWORD}>
              <PageTemplate>
                <ChangePasswordPage/>
              </PageTemplate>
            </RouteAuth>
            <RouteAuth path={paths.MAIN}>
              <SubPageTemplate subItemsMenu={[]} parentPath={''} path={''}>
                <Result
                  status="success"
                  title="Welcome to BinBot!"
                  subTitle="Click on menu to continue work."
                />
              </SubPageTemplate>
            </RouteAuth>
            <Route path="*" component={NotFoundPage}/>
          </Switch>
        </Router>
      </DataCommonProvider>
    </>
  );
}

export default App;
