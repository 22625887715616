import { Draggable } from 'react-beautiful-dnd';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Button, Modal,
} from 'antd';
import { LocationItem } from './locationItem';
import { FloorplanSvgEditor } from '../../../components/svg/FloorplanSvgEditor';
import { DIR_NAME_UPLOAD } from '../../SchedulePage';
import { FloorplanType, FootprintType, LocationType } from '../../../../../common/entityTypes';

export const LocationList = (props: any) => {
  const {
    locations, setSelectedFootprints, setIsFootorintChanged, setNewLocationsOrder,
  } = props;

  const [showModal, setShowModal] = useState<boolean>(false);
  const [currentFloorplan, setCurrentFloorplan] = useState<FloorplanType & { footprintData: FootprintType[] }>();

  const handleChangeFootprintDoService = (footprint: any, doService: boolean) => {
    const changedLocations = locations.map((location: any) => {
      if (location.location_id === footprint.location_id) {
        return {
          ...location,
          footprints: location.footprints.map((item: any) => {
            if (item.footprint_id === footprint.footprint_id) {
              return {
                ...item,
                raw_data_visit: {
                  ...item.raw_data_visit,
                  doService,
                },
              };
            }

            return item;
          }),
        };
      }

      return location;
    });

    setNewLocationsOrder(changedLocations);
    setIsFootorintChanged(true);
  };

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, []);

  useEffect(() => {
    setShowModal(!!currentFloorplan);
  }, [currentFloorplan]);

  return (
    <>
      <Modal
        title={`Floorplane - ${currentFloorplan?.name}`}
        visible={showModal}
        onCancel={closeModal}
        width={'70%'}
        footer={[
          <Button key="cancel" onClick={closeModal}>
            Close
          </Button>,
        ]}>
        {currentFloorplan
          ? <FloorplanSvgEditor
            linkUrl={`${process.env.REACT_APP_S3_STORAGE_URL}/${DIR_NAME_UPLOAD}/${currentFloorplan.picture_blob_name}`}
            nameFloorplan={currentFloorplan.name}
            listFootprint={currentFloorplan.footprintData}
            setKeyDisabledSave={() => {
            }}
            followChosenFootprint={false}
            highlightFootprint={false}
            isMoveMarkers={false}
            listVisitSchedule={
              locations.filter((location: LocationType) => location.location_id === currentFloorplan.location_id)[0].footprints
                .map((footprint: any) => ({
                  ...footprint.raw_data_visit,
                  footprint_id: footprint.footprint_id,
                }))
            }
          />
          : <p>Something wrong. Current floorplan doesn't chosen</p>
        }
      </Modal>
      {
        locations.map((location: any, index: number) => (
          <Draggable key={location.location_id} draggableId={location.location_id.toString()} index={index}>
            {(provided) => (
              <div
                className="draggable__item"
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <LocationItem
                  setSelectedFootprints={setSelectedFootprints}
                  handleChangeFootprintDoService={handleChangeFootprintDoService}
                  location={location}
                  setCurrentFloorplan={setCurrentFloorplan}
                />
              </div>
            )}
          </Draggable>
        ))
      }
    </>
  );
};
