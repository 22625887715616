import React, {
  useState, useRef, MutableRefObject, useCallback, Dispatch, SetStateAction,
} from 'react';
import { PositionType } from '../../../../common/typesEditor';

type CrossSvgProps = {
  etalonScale: MutableRefObject<null>,
  defaultPosition: PositionType,
  setPointScale?: Dispatch<SetStateAction<PositionType | undefined>>,
};

const size = 10;

export const CrossSvg: React.FC<CrossSvgProps> = ({ defaultPosition, etalonScale, setPointScale }) => {
  const [position, setPosition] = useState<PositionType>(defaultPosition);

  const getScale = useCallback(() => {
    const newScale = etalonScale.current ? (etalonScale.current as any).getClientRects()[0].width / 2000 : 1;
    return newScale;
  }, []);

  const handleMouseMove = useRef((e: any) => {
    setPosition((oldPosition: PositionType) => {
      const xDiff = oldPosition.coords ? oldPosition.coords.x - e.pageX : 0;
      const yDiff = oldPosition.coords ? oldPosition.coords.y - e.pageY : 0;
      const newX = oldPosition.x - xDiff / getScale();
      const newY = oldPosition.y - yDiff / getScale();

      return {
        x: newX,
        y: newY,
        coords: {
          x: e.pageX,
          y: e.pageY,
        },
      };
    });
  });

  const handleMouseDown = (e: any) => {
    const { pageX, pageY } = e;

    setPosition((_position: any) => ({
      ..._position,
      coords: {
        x: pageX,
        y: pageY,
      },
    }));
    document.addEventListener('mousemove', handleMouseMove.current);
    e.stopPropagation();
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove.current);

    if (setPointScale) setPointScale(position);
  };

  const functionHandelMouseUp = handleMouseUp;
  const functionHandelMouseDown = handleMouseDown;
  const styleCursorPointer = { cursor: 'pointer' };

  const buildLine = (buildSize1: number, buildSize2: number) => <g key={`key_${buildSize1}${buildSize2}`}>
      <line x1={position.x} y1={position.y}
            x2={+position.x + buildSize1} y2={+position.y + buildSize2}
            stroke={'white'} strokeWidth={8}
            style={styleCursorPointer}
            onMouseDown={functionHandelMouseDown}
            onMouseUp={functionHandelMouseUp}
      />
      <line x1={position.x} y1={position.y}
            x2={+position.x + buildSize1} y2={+position.y + buildSize2}
            stroke={'red'} strokeWidth={4} />
      <line x1={position.x} y1={position.y}
            x2={+position.x + buildSize1} y2={+position.y + buildSize2}
            stroke={'white'} opacity={0.01} strokeWidth={10}
            style={styleCursorPointer}
            onMouseDown={functionHandelMouseDown}
            onMouseUp={functionHandelMouseUp}
      />
    </g>;

  return (
      <g x={position.x} y={position.y}
         onMouseUp={functionHandelMouseUp}
      >
        {[
          buildLine(size, size),
          buildLine(-size, size),
          buildLine(size, -size),
          buildLine(-size, -size),
        ]}
      </g>
  );
};
