import {
  Button, DatePicker, Input, List, notification, Select, Typography,
} from 'antd';
import { DateTime } from 'luxon';
import { CheckCircleFilled } from '@ant-design/icons';
import { NotificationPlacement } from 'antd/es/notification';
import { useDispatch, useSelector } from 'react-redux';
import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined';
import React, {
  ReactNode, useCallback, useEffect, useState,
} from 'react';
import '../styles/LocationExceptionPeriodPage.scss';
import { useLocation } from 'react-router';
import { addSpinnerItem, removeSpinnerItem } from '../../../store/slices/spinnerSlice';
import { fetchLocation, updateLocation } from '../../../store/slices/locationSlice';
import { AppDispatch, RootState } from '../../../store';
import { LocationType } from '../../../../../common/entityTypes';

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Text } = Typography;

export interface IExceptionPeriod {
  exceptionPeriodId: number,
  exceptionPeriod: any,
  exceptionPeriodForecast: number,
  exceptionPeriodNotes: string,
}

export interface IExceptionPeriodOptions {
  label: string,
  value: number
}

const setUpExceptionsPeriodOptions = () => {
  const percents = [0, 10, 25, 50, 75, 125, 150];
  return percents.map((percent) => ({
    label: percent === 0 ? 'Location closed, scheduling disabled' : `${percent}% normal activity`,
    value: percent,
  }));
};

const exceptionsPeriodOptions: IExceptionPeriodOptions[] = setUpExceptionsPeriodOptions();

export const LocationDetailExceptionPeriodPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const useQuery = useCallback(() => new URLSearchParams(useLocation().search), []);
  const query = useQuery();
  const locationId = query.get('lid') || '';
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement: NotificationPlacement, message: string, desc: string, icon: ReactNode) => {
    api.info({
      message,
      description: desc,
      placement,
      icon,
    });
  };

  const [exceptionPeriod, setExceptionPeriod] = useState();
  const [exceptionPeriodForecast, setExceptionPeriodForecast] = useState();
  const [exceptionPeriodNotes, setExceptionPeriodNotes] = useState();
  const [listExceptionPeriod, setListExceptionPeriod] = useState<IExceptionPeriod[] | any>([]);

  const location = useSelector<RootState, LocationType | null>((store) => store.location.location);

  useEffect(() => {
    (async () => {
      dispatch(addSpinnerItem('location_exception_page'));
      await dispatch(fetchLocation(locationId));
      dispatch(removeSpinnerItem('location_exception_page'));
    })();
  }, []);

  useEffect(() => {
    if (location) {
      setListExceptionPeriod(JSON.parse(location.exception_period));
    }
  }, [location]);

  const handleAddExceptionPeriod = () => {
    const payload: IExceptionPeriod[] = [...listExceptionPeriod, {
      exceptionPeriodId: Number(new Date().getTime().toString().split('')
        .splice(9)
        .join('')),
      exceptionPeriod,
      exceptionPeriodForecast,
      exceptionPeriodNotes,
    }];

    setListExceptionPeriod(payload);
    setExceptionPeriodNotes(undefined);
    setExceptionPeriodForecast(undefined);
    setExceptionPeriod(undefined);
  };

  const handleExceptionPeriodNotes = (event: any) => {
    console.log('value', event.target.value);
    setExceptionPeriodNotes(event.target.value);
  };

  const handleExceptionPeriodForecast = (value: any) => {
    console.log('value', value);
    setExceptionPeriodForecast(value);
  };

  const handleExceptionPeriod = (value: any) => {
    console.log('value', value);
    setExceptionPeriod(value);
  };

  const handleUpdateExceptionPeriods = async () => {
    if (location) {
      dispatch(addSpinnerItem('location_exception_page'));
      await dispatch(updateLocation({
        ...location,
        exception_period: JSON.stringify(listExceptionPeriod),
      }));
      await dispatch(fetchLocation(locationId));
      const successMsg = 'Exception period saved successfully';
      const successDesc = '';
      const successIcon = <CheckCircleFilled style={{ color: 'green' }}/>;
      openNotification('topRight', successMsg, successDesc, successIcon);
      dispatch(removeSpinnerItem('location_exception_page'));
    }
  };

  const handleDeletePeriod = (id: number) => {
    setListExceptionPeriod(listExceptionPeriod.filter((period: IExceptionPeriod) => period.exceptionPeriodId !== id));
  };

  return (
    <>
      {contextHolder}
      <div className="location-exception">
        <List
          header={<Text>Exception periods</Text>}
          bordered
          dataSource={listExceptionPeriod}
          renderItem={(period: IExceptionPeriod, index) => {
            const periodFromOption = exceptionsPeriodOptions.find((periodOption) => periodOption.value === period.exceptionPeriodForecast);
            const dateForm = typeof period.exceptionPeriod[0] !== 'string' ? DateTime.fromJSDate(period.exceptionPeriod[0].toDate()).toFormat('y-LL-dd') : DateTime.fromISO(period.exceptionPeriod[0]).toFormat('y-LL-dd');
            const dateTo = typeof period.exceptionPeriod[1] !== 'string' ? DateTime.fromJSDate(period.exceptionPeriod[1].toDate()).toFormat('y-LL-dd') : DateTime.fromISO(period.exceptionPeriod[1]).toFormat('y-LL-dd');

            return (
              <List.Item key={period.exceptionPeriodId}>
                <Text>{index + 1}. {periodFromOption?.label} {dateForm} - {dateTo}</Text>
                <Text>{period.exceptionPeriodNotes}</Text>
                <Button onClick={() => handleDeletePeriod(period.exceptionPeriodId)}>
                  <DeleteOutlined/>
                </Button>
              </List.Item>
            );
          }}
        />
        <div className="location-exception__box">
          <Text>Period</Text> <RangePicker value={exceptionPeriod} className="location-exception__range-time"
                                           onChange={handleExceptionPeriod}/>
        </div>
        <div className="location-exception__box">
          <Text>Forecast</Text>
          <Select
            allowClear
            onChange={handleExceptionPeriodForecast}
            className="location-exception__select"
            placeholder="Select forecast"
            value={exceptionPeriodForecast}
          >
            {exceptionsPeriodOptions.map((period) => (
              <Option
                key={period.value}
                value={period.value}
              >
                {period.label}
              </Option>
            ))}
          </Select>
        </div>
        <div className="location-exception__box">
          <Text>Notes</Text> <Input value={exceptionPeriodNotes} placeholder="Write notes"
                                    onChange={handleExceptionPeriodNotes}/>
        </div>
        <div className="location-exception__box-actions">
          <Button
            disabled={!exceptionPeriodForecast || !exceptionPeriod}
            onClick={handleAddExceptionPeriod}
          >
            Add
          </Button>
          <Button type="primary" onClick={handleUpdateExceptionPeriods}>Save Exception Periods</Button>
        </div>
      </div>
    </>
  );
};
