import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { ERequestRoute, ERequestUrl } from '../config';
import { WorkNoteType } from '../../../../common/entityTypes';

export interface IWorkNoteState {
  workNoteList: WorkNoteType[];
}

const initialState: IWorkNoteState = {
  workNoteList: [],
};

export const fetchWorkNoteById = createAsyncThunk('workNoteList/fetch', async (Ids: string) => {
  const response = await axios.get<WorkNoteType[]>(`${ERequestUrl.BASE_CRUD}/${ERequestRoute.WORK_NOTE}/?${Ids}`);
  return response.data;
});

export const addNote = createAsyncThunk('note/add', async (payload: any) => {
  const response = await axios.post<WorkNoteType[]>(`${ERequestUrl.BASE_CRUD}/${ERequestRoute.WORK_NOTE}`, payload);
  return response.data;
});

export const deleteNote = createAsyncThunk('delete/add', async (workNoteId: any) => {
  const response = await axios.delete<WorkNoteType[]>(`${ERequestUrl.BASE_CRUD}/${ERequestRoute.WORK_NOTE}/${workNoteId}`);
  return response.data;
});

export const updateNote = createAsyncThunk('update/add', async ({ workNoteId, payload }: any) => {
  const response = await axios.post<WorkNoteType[]>(`${ERequestUrl.BASE_CRUD}/${ERequestRoute.WORK_NOTE}/${workNoteId}`, payload);
  return response.data;
});

export const workNoteSlice = createSlice({
  name: 'work_note',
  initialState,
  reducers: {
    editWorkNote: (state, action) => {
      state.workNoteList = state.workNoteList.map((note) => (note.work_note_id === action.payload.id ? {
        ...note,
        edit: action.payload.edit,
      } : note));
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWorkNoteById.fulfilled, (state, action: PayloadAction<WorkNoteType[]>) => {
      state.workNoteList = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  editWorkNote,
} = workNoteSlice.actions;

export default workNoteSlice.reducer;
