import React from 'react';
import { Link } from 'react-router-dom';
import { ColumnsType } from 'antd/es/table';
import { Spin } from 'antd';
import {
  CustomerType, CycleType, LocationType, RegionType, ScheduleType, WarehouseType,
} from '../../../../../common/entityTypes';
import { paths } from '../../../common';

export const getColumns = (
  toggleEditModal: (raw: LocationType) => void,
  regionList: RegionType[],
  customerList: CustomerType[],
  scheduleList: ScheduleType[],
  cycleList: CycleType[],
  warehouseList: WarehouseType[],
): ColumnsType<LocationType> => [
  {
    title: 'Location Name',
    dataIndex: 'location_name',
    key: 'location_name',
    sorter: (a: any, b: any) => a.location_name.localeCompare(b.location_name),
    render: (value, raw) => {
      const link = `${paths.SETUP}${paths.LOCATIONS_DETAIL}?r=main&lid=${raw.location_id}`;
      return <Link className="table__link" to={link}>{value}</Link>;
    },
  },
  {
    title: 'Region',
    dataIndex: 'region_id',
    key: 'region_id',
    render: (value: number) => {
      if (regionList.length) {
        return regionList.find((region) => region.region_id === value)!.region_name;
      }

      return <Spin/>;
    },
  },
  {
    title: 'Warehouse',
    dataIndex: 'warehouse_id',
    key: 'warehouse_id',
    render: (value: number) => {
      if (warehouseList.length) {
        return warehouseList.find((warehouse) => warehouse.warehouse_id === value)?.warehouse_name!;
      }

      return <Spin/>;
    },
  },
  {
    title: 'Customer',
    dataIndex: 'customer_id',
    key: 'customer_id',
    render: (value: number) => {
      if (customerList.length) {
        return customerList.find((customer) => customer.customer_id === value)?.customer_name;
      }
      return <Spin/>;
    },
  },
  {
    title: 'Schedule',
    dataIndex: 'schedule_id',
    key: 'schedule_id',
    render: (value: number) => {
      if (customerList.length) {
        return scheduleList.find((schedule) => schedule.schedule_id === value)?.schedule_name;
      }
      return <Spin/>;
    },
  },
  {
    title: 'Cycle',
    dataIndex: 'cycle_id',
    key: 'cycle_id',
    render: (value: number) => {
      if (cycleList.length) {
        return cycleList.find((cycle) => cycle.cycle_id === value)?.cycle_name;
      }
      return <Spin/>;
    },
  },
  {
    title: 'City',
    dataIndex: 'city',
    key: 'city',
    sorter: (a: any, b: any) => a.city.localeCompare(b.city),
  },
  {
    title: 'Address 1',
    dataIndex: 'address1',
    key: 'address1',
  },
  {
    title: 'Address 2',
    dataIndex: 'address2',
    key: 'address2',
  },
  {
    title: 'State',
    dataIndex: 'state_province',
    key: 'state_province',
    sorter: (a: any, b: any) => a.state_province.localeCompare(b.state_province),
  },
  {
    title: 'Zip',
    dataIndex: 'zip',
    key: 'zip',
    sorter: (a: any, b: any) => (a.zip > b.zip ? 1 : -1),
  },
  {
    title: 'Action',
    dataIndex: 'key',
    key: 'key',
    render: (value: string, raw: LocationType) => (
      <div onClick={() => toggleEditModal(raw)}
           style={{ fontWeight: 'bold', margin: '0 10px', color: '#1890ff' }}>
        Edit
      </div>
    ),
  },
];
