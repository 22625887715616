import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Table } from 'antd';
import { TablePaginationConfig } from 'antd/es/table';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store';
import { FloorplanType, FootprintType } from '../../../../../common/entityTypes';
import { PAGINATION_TABLE } from '../../../consts';
import { getFootprintColumns } from '../config/tableFootorintConfig';
import { addSpinnerItem, removeSpinnerItem } from '../../../store/slices/spinnerSlice';
import { fetchFloorplanById } from '../../../store/slices/floorplanSlice';

export const LocationDetailFootprintsPage = () => {
  const useQuery = useCallback(() => new URLSearchParams(useLocation().search), []);
  const query = useQuery();
  const locationId = query.get('lid') || '';
  const dispatch = useDispatch<AppDispatch>();

  const [paginationTable, setPaginationTable] = useState<TablePaginationConfig>(PAGINATION_TABLE);
  const handleTableChange = useCallback((pagination) => {
    setPaginationTable(pagination);
  }, []);

  const footprintList = useSelector<RootState, FootprintType[]>((store) => store.footprint.footprintList);
  const floorplanList = useSelector<RootState, FloorplanType[]>((store) => store.floorplan.floorplanList);

  useEffect(() => {
    if (locationId) {
      (async () => {
        dispatch(addSpinnerItem('location_footprints_page'));
        await dispatch(fetchFloorplanById(`location_id=${locationId}`));
        dispatch(removeSpinnerItem('location_footprints_page'));
      })();
    }
  }, [locationId]);

  return (
    <Table
      columns={getFootprintColumns(floorplanList)}
      className={'click_row'}
      dataSource={footprintList}
      pagination={paginationTable}
      onChange={handleTableChange}
    />
  );
};
