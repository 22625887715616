import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { ERequestRoute, ERequestUrl } from '../config';
import { FloorplanType } from '../../../../common/entityTypes';

export interface IFloorplanState {
  floorplanList: FloorplanType[];
}

const initialState: IFloorplanState = {
  floorplanList: [],
};

export const fetchFloorplan = createAsyncThunk('floorplanList/fetch', async (location_id?: string) => {
  const response = await axios.get<FloorplanType[]>(`${ERequestUrl.BASE_CRUD}/${ERequestRoute.FLOORPLAN}?${location_id || ''}`);
  return response.data;
});

export const fetchFloorplanById = createAsyncThunk('floorplanListById/fetch', async (ids: string) => {
  const response = await axios.get<FloorplanType[]>(`${ERequestUrl.BASE_CRUD}/${ERequestRoute.FLOORPLAN}?${ids}`);
  return response.data;
});

export const floorplanSlice = createSlice({
  name: 'floorplan',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFloorplan.fulfilled, (state, action: PayloadAction<FloorplanType[]>) => {
      state.floorplanList = action.payload;
    });
    builder.addCase(fetchFloorplanById.fulfilled, (state, action: PayloadAction<FloorplanType[]>) => {
      state.floorplanList = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
// export const {} = counterSlice.actions;

export default floorplanSlice.reducer;
