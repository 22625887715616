import * as React from 'react';
import {
  Button, Form, FormInstance, Input, Select,
} from 'antd';
import {
  Dispatch, SetStateAction, useCallback, useEffect, useState,
} from 'react';
import { PositionType } from '../../../common/typesEditor';

type ScaleFloorplanProps = {
  form: FormInstance,
  onSave: () => {},
  modeSetScale: boolean,
  setModeSetScale: Dispatch<SetStateAction<boolean>>
  pointScale1: PositionType,
  pointScale2: PositionType
};

type MeasureLengthType = 'ft' | 'm';

const SCALA_M_FT = 3.28084;

const squareDiff = (a: number, b: number) => (a - b) ** 2;

export const ScaleFloorplan: React.FC<ScaleFloorplanProps> = ({
  form, onSave, modeSetScale, setModeSetScale, pointScale1, pointScale2,
}) => {
  const [length, setLength] = useState<number>(0);

  const [subForm] = Form.useForm();

  const calcScale = useCallback((_length, _distance) => {
    const scale = _length && _distance ? Math.round((_distance / _length) * 1000) / 1000 : 0;
    if (scale) {
      form.setFieldsValue({ scale });
    }
    return scale;
  }, []);

  useEffect(() => {
    const distance = subForm.getFieldValue('sub_distance');
    subForm.setFieldsValue({ sub_length: length, sub_scale: calcScale(length, distance) });
  }, [length]);

  useEffect(() => {
    if (pointScale1 && pointScale2) {
      setLength(Math.round(Math.sqrt(squareDiff(pointScale1.x, pointScale2.x) + squareDiff(pointScale1.y, pointScale2.y))));
    }
  }, [pointScale1, pointScale2]);

  const onSetScale = useCallback(async () => {
    setModeSetScale(!modeSetScale);
  }, [modeSetScale]);

  const onSaveScale = useCallback(async () => {
    setModeSetScale(!modeSetScale);
    onSave();
  }, [modeSetScale]);

  const onChangeDistance = useCallback(() => {
    const measureType = subForm.getFieldValue('sub_measure_type') as MeasureLengthType;

    let distanceForCalc = subForm.getFieldValue('sub_distance');
    if (measureType === 'ft') {
      distanceForCalc /= SCALA_M_FT;
    }

    subForm.setFieldsValue({ sub_scale: calcScale(length, distanceForCalc) });
  }, [length]);

  return (
    <>
        {
            !modeSetScale
              ? <Button type={'primary'}
                        onClick={onSetScale}
                >Set scale</Button>
              : <div style={{ display: 'inline-block' }}>
                    <Form
                        form={subForm}
                        layout={'inline'}
                        name="addScaleFloorplanItem"
                        initialValues={{ distance: 0 }}
                        autoComplete="nope"
                    >
                      {/*  <span style={{ lineHeight: 2.2, padding: '0 10px' }}>P1({Math.round(pointScale1?.x) || '__'}, {Math.round(pointScale1?.y) || '__'});</span> */}
                      {/*  <span style={{ lineHeight: 2.2, padding: '0 10px' }}>P2({Math.round(pointScale2?.x) || '__'}, {Math.round(pointScale2?.y) || '__'})</span> */}
                      {/* <span style={{ lineHeight: 2.2, padding: '0 5px' }}>Distance (px): </span> */}
                      <Form.Item
                          name={'sub_length'}
                          style={{ width: '70px', display: 'none' }}
                      >
                        <Input disabled={true} placeholder="distance ..." autoComplete={'nope'} />
                      </Form.Item>
                         <span style={{ lineHeight: 2.2, padding: '0 5px 0 15px' }}>Distance (m): </span>
                        <Form.Item
                            name={'sub_distance'}
                            style={{ width: '70px' }}
                        >
                            <Input placeholder="distance ..." autoComplete={'nope'} onChange={onChangeDistance} />
                        </Form.Item>
                      <Form.Item
                          name={'sub_scale'}
                          style={{ width: '70px', display: 'none' }}
                      >
                        <Input disabled={true} placeholder="scale ..." autoComplete={'nope'} />
                      </Form.Item>
                      <Form.Item
                          name={'sub_measure_type'}
                          style={{ width: '70px' }}
                      >
                        <Select
                            defaultValue="ft"
                            onChange={onChangeDistance}
                            options={[
                              { value: 'ft', label: 'ft' },
                              { value: 'm', label: 'm' },
                            ]}
                        />
                      </Form.Item>
                        <Button type={'primary'}
                                onClick={onSaveScale}
                        >Save scale</Button>
                    </Form>
                </div>
        }
    </>
  );
};
