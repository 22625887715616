import React, {
  ReactElement, useCallback, useEffect, useState,
} from 'react';
import { Spin } from 'antd';
import Badge from 'antd/lib/badge';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router';
import Title from 'antd/es/typography/Title';
import { useDispatch, useSelector } from 'react-redux';
import { LocationDetailMainPage } from './LocationDetailMainPage';
import { LocationDetailWorkNotesPage } from './LocationDetailWorkNotesPage';
import { LocationDetailFootprintsPage } from './LocationDetailFootprintsPage';
import { paths } from '../../../common';
import '../styles/LocationDetailPage.scss';
import { LocationDetailExceptionPeriodPage } from './LocationDetailExceptionPeriodPage';
import { LocationDetailSettingsPage } from './LocationDetailSettingsPage';
import { LocationDetailPageType, LocationDetailRoutesType } from '../../../../../common/types';
import { fetchLocation } from '../../../store/slices/locationSlice';
import { AppDispatch, RootState } from '../../../store';
import { FootprintType, LocationType, WorkNoteType } from '../../../../../common/entityTypes';
import { fetchWorkNoteById } from '../../../store/slices/workNoteSlice';
import { fetchFootprintListById } from '../../../store/slices/footprintSlice';

export const LocationDetail = () => {
  const useQuery = useCallback(() => new URLSearchParams(useLocation().search), []);
  const query = useQuery();
  const route = query.get('r') || '';
  const locationId = query.get('lid') || '';
  const dispatch = useDispatch<AppDispatch>();

  const [render, setRender] = useState<ReactElement<any, any> | null>(null);

  const footprintList = useSelector<RootState, FootprintType[]>((store) => store.footprint.footprintList);
  const location = useSelector<RootState, LocationType | null>((store) => store.location.location);
  const workNoteList = useSelector<RootState, WorkNoteType[]>((store) => store.workNote.workNoteList);
  const spinner = useSelector<RootState, boolean>((store) => store.spinner.spinner);

  const locationDetailRoutes: LocationDetailRoutesType = {
    main: <LocationDetailMainPage/>,
    note: <LocationDetailWorkNotesPage/>,
    footprints: <LocationDetailFootprintsPage/>,
    exception: <LocationDetailExceptionPeriodPage/>,
    schedule_settings: <LocationDetailSettingsPage/>,
  };

  useEffect(() => {
    setRender(locationDetailRoutes[route as LocationDetailPageType]);
  }, [route]);

  useEffect(() => {
    (async () => {
      await dispatch(fetchFootprintListById(`location_id=${locationId}`));
      await dispatch(fetchLocation(locationId));
      await dispatch(fetchWorkNoteById(`location_id=${locationId}`));
    })();
  }, []);

  return (
    <div>
      <div className="navigation">
        <NavLink
          activeClassName={route === 'main' ? 'navigation__link--active' : ''}
          className="navigation__link navigation__link--badge"
          to={`${paths.SETUP}${paths.LOCATIONS_DETAIL}?r=main&lid=${locationId}`}
        >
          Main
        </NavLink>
        <Badge offset={[0, 30]} count={workNoteList.filter((note) => !note.completed).length}>
          <NavLink
            activeClassName={route === 'note' ? 'navigation__link--active' : ''}
            className="navigation__link navigation__link--badge"
            to={`${paths.SETUP}${paths.LOCATIONS_DETAIL}?r=note&lid=${locationId}`}
          >
            Note
          </NavLink>
        </Badge>
        <Badge offset={[0, 30]} count={footprintList?.length}>
          <NavLink
            activeClassName={route === 'footprints' ? 'navigation__link--active' : ''}
            className="navigation__link navigation__link--badge"
            to={`${paths.SETUP}${paths.LOCATIONS_DETAIL}?r=footprints&lid=${locationId}`}
          >
            Footprints
          </NavLink>
        </Badge>
        <Badge offset={[0, 30]} count={JSON.parse(location?.exception_period || '[]')?.length}>
          <NavLink
            activeClassName={route === 'exception' ? 'navigation__link--active' : ''}
            className="navigation__link navigation__link--badge"
            to={`${paths.SETUP}${paths.LOCATIONS_DETAIL}?r=exception&lid=${locationId}`}
          >
            Exception periods
          </NavLink>
        </Badge>
        <NavLink
          activeClassName={route === 'schedule_settings' ? 'navigation__link--active' : ''}
          className="navigation__link navigation__link--badge"
          to={`${paths.SETUP}${paths.LOCATIONS_DETAIL}?r=schedule_settings&lid=${locationId}`}
        >
          Schedule settings
        </NavLink>
      </div>
      <Title
        className="navigation__title"
        level={2}
      >
        {location && !location.location_name ? 'Location detail' : location?.location_name}
      </Title>
      <Spin spinning={spinner} tip={'please wait ...'}>
        <div className={route !== 'footprints' ? 'layout layout--width' : 'layout'}>
          {render}
        </div>
      </Spin>
    </div>
  );
};
