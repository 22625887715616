import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import React, { ReactNode, useCallback, useEffect } from 'react';
import { NotificationPlacement } from 'antd/es/notification';
import { CheckCircleFilled } from '@ant-design/icons';
import { useJsApiLoader } from '@react-google-maps/api';
import {
  Button,
  Checkbox, Form, Input, notification,
} from 'antd';
import { AppDispatch, RootState } from '../../../store';
import {
  fetchLocation,
  updateLocation,
} from '../../../store/slices/locationSlice';
import {
  CustomerType, LocationType,
} from '../../../../../common/entityTypes';
import { addSpinnerItem, removeSpinnerItem } from '../../../store/slices/spinnerSlice';
import { fetchCustomersByIds } from '../../../store/slices/customerSlice';
import '../styles/LocationMainPage.scss';

const optionsOpenDays = [
  { label: 'Sun', value: 'Sun' },
  { label: 'Mon', value: 'Mon' },
  { label: 'Tue', value: 'Tue' },
  { label: 'Wed', value: 'Wed' },
  { label: 'Thu', value: 'Thu' },
  { label: 'Fri', value: 'Fri' },
  { label: 'Sat', value: 'Sat' },
];

const defaultOpenDaysOptions = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'];

export const LocationDetailMainPage = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();
  const useQuery = useCallback(() => new URLSearchParams(useLocation().search), []);
  const query = useQuery();
  const locationId = query.get('lid') || '';
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyBbpAbovI6ok3Ort4b897S8rbkykCUUQxE', // ,
  });
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement: NotificationPlacement, message: string, desc: string, icon: ReactNode) => {
    api.info({
      message,
      description: desc,
      placement,
      icon,
    });
  };

  const location = useSelector<RootState, LocationType | null>((store) => store.location.location);
  const customerList = useSelector<RootState, CustomerType[]>((store) => store.customer.customerList);

  useEffect(() => {
    if (location) {
      (async () => {
        dispatch(addSpinnerItem('location_detail_page'));
        await dispatch(fetchCustomersByIds(`customer_id=${location.customer_id}`));
        dispatch(removeSpinnerItem('location_detail_page'));
      })();
    }
  }, [location]);

  useEffect(() => {
    if (location) {
      const daysOpen = location.days_open ? JSON.parse(location.days_open) : [];

      form.setFieldsValue({
        ...location,
        days_open: daysOpen,
      });
    }
  }, [location]);

  const getPosition = async (address: string) => {
    let position = {
      lat: 0,
      lng: 0,
    };

    if (isLoaded) {
      const geocoder = await new google.maps.Geocoder();
      await geocoder.geocode({ address }, (results, status) => {
        if (status === 'OK' && results) {
          position = {
            lat: +results[0]?.geometry.location.lat(),
            lng: +results[0]?.geometry.location.lng(),
          };
        }
      });
    }

    if (loadError) {
      console.log(loadError);
    }

    return position;
  };

  const handleOnFinish = async (values: LocationType) => {
    console.log('values', values);
    dispatch(addSpinnerItem('location_page'));
    if (!location?.longitude || !location?.latitude || values.address1 !== location?.address1) {
      const position = await getPosition(`${values.address1}, ${values.city}, ${values.state_province} ${values.zip}, ${values.country}`);
      values = { ...values, longitude: position.lng, latitude: position.lat };
    }
    await dispatch(updateLocation({
      ...values,
      days_open: JSON.stringify(values.days_open),
      location_id: location?.location_id,
    }));
    const successMsg = 'Location saved successfully';
    const successDesc = '';
    const successIcon = <CheckCircleFilled style={{ color: 'green' }}/>;
    openNotification('topRight', successMsg, successDesc, successIcon);
    await dispatch(fetchLocation(locationId));
    dispatch(removeSpinnerItem('location_page'));
  };

  return (
    <>
      {contextHolder}
      {location
        && <Form
          form={form}
          name="location"
          layout="vertical"
          onFinish={handleOnFinish}
          autoComplete="nope"
          className="location"
        >
          <div className="location__box">
            <p className="location__box-placeholder">Customer </p>
            <p className="location__box-label">{customerList[0]?.customer_name}</p>
          </div>
          <Form.Item
            label="Permanent location notes"
            name={'permanent_location_notes'}
          >
            <Input.TextArea
              placeholder="Write permanent location notes"
              autoSize={{ minRows: 2 }}
            />
          </Form.Item>
          <Form.Item
            label="Days open"
            name={'days_open'}
            style={{ margin: 0 }}
            rules={[
              { required: true, message: 'Please select days open!' },
            ]}
            initialValue={form.getFieldValue('days_open') || defaultOpenDaysOptions}
          >
            <Checkbox.Group options={optionsOpenDays}/>
          </Form.Item>
          <Form.Item
            label="Name"
            name={'location_name'}
            rules={[
              { required: true, message: 'Please input location name!' },
            ]}
            initialValue={location.location_name}
          >
            <Input/>
          </Form.Item>
          <Form.Item
            label="Country"
            name={'country'}
            initialValue={location.country}
          >
            <Input/>
          </Form.Item>
          <Form.Item
            label="City"
            name={'city'}
            initialValue={location.city}
          >
            <Input/>
          </Form.Item>
          <Form.Item
            label="State Province"
            name={'state_province'}
            initialValue={location.state_province}
          >
            <Input/>
          </Form.Item>
          <Form.Item
            label="Zip"
            name={'zip'}
            initialValue={location.zip}
          >
            <Input/>
          </Form.Item>
          <Form.Item
            label="Address 1"
            name={'address1'}
            initialValue={location.address1}
          >
            <Input/>
          </Form.Item>
          <Form.Item
            label="Address 2"
            name={'address2'}
            initialValue={location.address2}
          >
            <Input/>
          </Form.Item>
          <div className="location__buttons">
            <Button name="location" type="primary" htmlType="submit">
              Save
            </Button>
          </div>
        </Form>
      }
    </>
  );
};
