import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IConfigInterface, shred, demodomain } from '../../domainConfig';

interface IConfigs {
  shred: IConfigInterface
  demodomain: IConfigInterface
}

const configs: IConfigs = {
  shred,
  demodomain,
};

export interface IAppState {
  config: IConfigInterface | null;
}

const initialState: IAppState = {
  config: null,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setDomain: (state, action: PayloadAction<string>) => {
      // @ts-ignore
      state.config = configs[action.payload];
      localStorage.setItem('domain', action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setDomain,
} = appSlice.actions;

export default appSlice.reducer;
