import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { LocationList } from './locationList';

const reorder = (list: any, startIndex: number, endIndex: number) => {
  const result: any = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  const lineIndex = result.findIndex((item: any) => item.location_id === 0);

  if (lineIndex >= 0) {
    for (let i = lineIndex; i >= 0; i--) {
      if (result[i - 1]) result[i - 1].underLine = false;
    }

    for (let i = lineIndex; i <= result.length; i++) {
      if (result[i + 1]) result[i + 1].underLine = true;
    }
  }

  return result;
};

const line = {
  location_id: 0,
  type: 'line',
  location_name: '"The line"',
  desc: 'Locations below here won\'t be serviced. To execute scheduling a location, drag it below the line. To service more or fewer locations, drag the line',
};

export const DragAndDrop = (props: any) => {
  const {
    locationData,
    setIsDrugged,
    setNewLocationsOrder,
    setSelectedFootprints,
    response,
    timeToWarehouse,
    driverWorkHours,
    setIsFootorintChanged,
    isFootorintChanged,
  } = props;

  const [servicedLocationData, setServicedLocationData] = useState<any>([]);
  let driverWorkTimeThreshold = 0;

  useEffect(() => {
    let sortedLocationData = locationData
      .sort((a: any, b: any) => a.underLine - b.underLine);
    const lastDoServiceIndex = sortedLocationData.findIndex((location: any) => location.underLine);

    if (lastDoServiceIndex > 0) {
      const underLineLocations = sortedLocationData
        .slice(lastDoServiceIndex)
        .sort((a: any, b: any) => a.order - b.order)
        .map((location: any) => ({
          ...location,
          underLine: true,
        }));
      const aboveLineLocations = sortedLocationData
        .slice(0, lastDoServiceIndex)
        .sort((a: any, b: any) => a.order - b.order)
        .map((location: any) => {
          driverWorkTimeThreshold += location.location_time + location.traffic_time;
          const driverOutOfWork = (driverWorkTimeThreshold + timeToWarehouse) / 60 > driverWorkHours;
          const driverOutOfTime = driverOutOfWork ? (driverWorkTimeThreshold + timeToWarehouse) - (driverWorkHours * 60) : 0;

          return {
            ...location,
            underLine: false,
            driverOutOfWork,
            driverOutOfTime,
          };
        });

      sortedLocationData = [
        ...aboveLineLocations,
        line,
        ...underLineLocations,
      ];
    }

    if (lastDoServiceIndex === 0) {
      sortedLocationData = [
        line,
        ...sortedLocationData
          .sort((a: any, b: any) => a.order - b.order)
          .map((location: any) => ({
            ...location,
            underLine: true,
          })),
      ];
    }

    if (lastDoServiceIndex < 0) {
      sortedLocationData = [
        ...sortedLocationData
          .sort((a: any, b: any) => a.order - b.order)
          .map((location: any) => {
            driverWorkTimeThreshold += location.location_time + location.traffic_time;
            const driverOutOfWork = (driverWorkTimeThreshold + timeToWarehouse) / 60 > driverWorkHours;
            const driverOutOfTime = driverOutOfWork ? (driverWorkTimeThreshold + timeToWarehouse) - (driverWorkHours * 60) : 0;

            return {
              ...location,
              underLine: false,
              driverOutOfWork,
              driverOutOfTime,
            };
          }),
        line,
      ];
    }

    setServicedLocationData(sortedLocationData);
  }, [locationData, response, timeToWarehouse, isFootorintChanged]);

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const newLocations = reorder(
      servicedLocationData,
      result.source.index,
      result.destination.index,
    );

    setNewLocationsOrder(newLocations);
    setIsDrugged(true);
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div
              className="draggable__list"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              <LocationList
                setNewLocationsOrder={setNewLocationsOrder}
                setSelectedFootprints={setSelectedFootprints}
                locations={servicedLocationData}
                setIsFootorintChanged={setIsFootorintChanged}
              />
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};
