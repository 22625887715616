import { ColumnsType } from 'antd/es/table';
import { FloorplanType, FootprintType } from '../../../../../common/entityTypes';

export const getFootprintColumns = (floorplanList: FloorplanType[]): ColumnsType<FootprintType> => [
  {
    title: 'Building',
    dataIndex: 'building',
    key: 'building',
    render: (text, record) => {
      console.log('record', record);
      const floorplan = floorplanList.find((fp) => fp.location_id === record.location_id);
      return floorplan?.name || '';
    },
  },
  {
    title: 'Floor',
    dataIndex: 'floor',
    key: 'floor',
    render: (text, record) => {
      console.log('record', record);
      const floorplan = floorplanList.find((fp) => fp.location_id === record.location_id);
      return floorplan?.floor_number || '';
    },
  },
  {
    title: 'Footprint name',
    dataIndex: 'partner_footprint_id',
    key: 'partner_footprint_id',
  },
  {
    title: 'Container barcode',
    dataIndex: 'container_footprint_name',
    key: 'container_footprint_name',
  },
];
