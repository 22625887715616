import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Title from 'antd/es/typography/Title';
import { Spin } from 'antd';
import { NavLink } from 'react-router-dom';
import { AppDispatch, RootState } from '../../../store';
import { addSpinnerItem, removeSpinnerItem } from '../../../store/slices/spinnerSlice';
import { fetchRouteLocations, fetchRoutesById } from '../../../store/slices/routeSlice';
import { LocationType, RouteLocationType, RouteType } from '../../../../../common/entityTypes';
import { fetchLocationsById } from '../../../store/slices/locationSlice';
import { paths } from '../../../common';
import '../styles/RouteDetailPage.scss';

export const RouteDetailPage = () => {
  const useQuery = useCallback(() => new URLSearchParams(useLocation().search), []);
  const query = useQuery();
  const routeId = query.get('rid') || '';
  const dispatch = useDispatch<AppDispatch>();

  const routeList = useSelector<RootState, RouteType[]>((store) => store.route.routeList);
  const locationList = useSelector<RootState, LocationType[]>((store) => store.location.locationList);
  const routeLocationList = useSelector<RootState, RouteLocationType[]>((store) => store.route.routeLocationList);
  const spinner = useSelector<RootState, boolean>((store) => store.spinner.spinner);

  useEffect(() => {
    (async () => {
      dispatch(addSpinnerItem('route_detail_page'));
      await dispatch(fetchRoutesById(`route_id=${routeId}`));
      dispatch(removeSpinnerItem('route_detail_page'));
    })();
  }, []);

  useEffect(() => {
    if (routeList?.length === 1) {
      (async () => {
        dispatch(addSpinnerItem('route_detail_page'));
        await dispatch(fetchLocationsById(`warehouse_id=${routeList[0].warehouse_id}`));
        await dispatch(fetchRouteLocations({ route_id: Number(routeId) }));
        dispatch(removeSpinnerItem('route_detail_page'));
      })();
    }
  }, [routeList]);

  return (
    <Spin spinning={spinner} tip={'please wait ...'}>
      <div className="route-detail">
        <Title level={2}>
          {routeList[0]?.route_name}
        </Title>
        {!!routeLocationList?.length && !!locationList?.length
          && <>
            <div className="route-detail__location-list">
              {routeLocationList.map((routeLocation) => (
                <NavLink
                  className="route-detail__location-item"
                  key={routeLocation.route_location_id}
                  to={`${paths.SETUP}${paths.LOCATIONS_DETAIL}?r=main&lid=${routeLocation.location_id}`}
                >
                  {locationList?.find((location) => location.location_id === routeLocation.location_id)?.location_name}
                </NavLink>
              ))}
            </div>
          </>
        }
      </div>
    </Spin>
  );
};
