import { useDispatch, useSelector } from 'react-redux';
import React, {
  ReactNode, useCallback, useEffect,
} from 'react';
import { NotificationPlacement } from 'antd/es/notification';
import { CheckCircleFilled } from '@ant-design/icons';
import {
  Button,
  Form, notification, Tabs,
} from 'antd';
import { useLocation } from 'react-router';
import { DateTime } from 'luxon';
import { AppDispatch, RootState } from '../../../store';
import {
  ScheduleSettingType,
} from '../../../../../common/entityTypes';
import { addSpinnerItem, removeSpinnerItem } from '../../../store/slices/spinnerSlice';
import '../styles/LocationMainPage.scss';
import { TabDynamic } from '../components/TabDynamic';
import { TabInterval } from '../components/TabInterval';
import { TabStatic } from '../components/TabStatic';
import {
  addLocationScheduleSetting,
  fetchLocationScheduleSetting,
} from '../../../store/slices/locationSlice';
import { daysWeek } from '../../../common/web_consts';
import { DayWeekBooleanType } from '../../../../../common/types';

const { TabPane } = Tabs;

export const LocationDetailSettingsPage = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement: NotificationPlacement, message: string, desc: string, icon: ReactNode) => {
    api.info({
      message,
      description: desc,
      placement,
      icon,
    });
  };
  const useQuery = useCallback(() => new URLSearchParams(useLocation().search), []);
  const query = useQuery();
  const locationId = query.get('lid') || '';

  const scheduleSetting = useSelector<RootState, ScheduleSettingType | null>((store) => store.location.locationScheduleSetting);

  console.log('scheduleSetting', scheduleSetting);
  useEffect(() => {
    (async () => {
      dispatch(addSpinnerItem('location_detail_page'));
      await dispatch(fetchLocationScheduleSetting(locationId));
      dispatch(removeSpinnerItem('location_detail_page'));
    })();
  }, []);

  const handleOnFinish = async (values: any) => {
    console.log('values', values, scheduleSetting);

    if (!scheduleSetting) {
      return;
    }

    dispatch(addSpinnerItem('location_page'));

    const getFieldDaysWeek = (index: number): DayWeekBooleanType | undefined => {
      if (values.recurrence.toString().split(' ')[0] < index) {
        return undefined;
      }

      const result: { [key: string]: boolean } = {};
      for (const dayWeek of daysWeek) {
        result[dayWeek.toLowerCase()] = Boolean(values[`${index}_${dayWeek}`]);
      }
      return result as DayWeekBooleanType;
    };

    await dispatch(addLocationScheduleSetting({
      location_id: locationId as any as number,
      save_datetime: DateTime.now().toISO(),
      days_between_service: values.days_between_service,
      fill_trigger_percent: values.fill_trigger_percent,
      flexibility: values.flexibility,
      max_day_without_visit: values.max_day_without_visit,
      distance_between_container_in_group: values.distance_between_container_in_group,
      recurrence: values.recurrence,
      trigger_service: values.trigger_service,
      week_1: getFieldDaysWeek(1)!,
      week_2: getFieldDaysWeek(2),
      week_3: getFieldDaysWeek(3),
      week_4: getFieldDaysWeek(4),
    }));

    const successMsg = 'Schedule Settings saved successfully';
    const successDesc = '';
    const successIcon = <CheckCircleFilled style={{ color: 'green' }}/>;
    openNotification('topRight', successMsg, successDesc, successIcon);
    dispatch(removeSpinnerItem('location_page'));
  };

  const onChangeTabLocation = useCallback((e) => {
    console.log('click tab', e);
  }, []);

  const initVal = () => {
    if (!scheduleSetting) {
      return {};
    }
    const result: any = {
      fill_trigger_percent: scheduleSetting.fill_trigger_percent,
      max_day_without_visit: scheduleSetting.max_day_without_visit,
      distance_between_container_in_group: scheduleSetting.distance_between_container_in_group,
      days_between_service: scheduleSetting.days_between_service,
      flexibility: scheduleSetting.flexibility,
      recurrence: scheduleSetting.recurrence,
      trigger_service: scheduleSetting.trigger_service,
    };

    for (let indexWeek = 1; indexWeek <= 4; indexWeek++) {
      for (const dayWeek of daysWeek) {
        const week = (scheduleSetting as any)[`week_${indexWeek}`];
        if (week) {
          result[`${indexWeek}_${dayWeek}`] = week[dayWeek.toLowerCase()];
        }
      }
    }

    return result;
  };

  return (
    <>
      {contextHolder}
      {
        !!scheduleSetting
        && <Form
          form={form}
          name="location"
          layout="vertical"
          onFinish={handleOnFinish}
          initialValues={initVal()}
          autoComplete="nope"
          className="location"
        >
          <Tabs defaultActiveKey="1" onChange={onChangeTabLocation} type="card">
            <TabPane tab={'Dynamic'} key={'dynamic'} forceRender={true}>
              {
                <TabDynamic/>
              }
            </TabPane>
            <TabPane tab={'Interval'} key={'interval'} forceRender={true}>
              {
                <TabInterval/>
              }
            </TabPane>
            <TabPane tab={'Static'} key={'static'} forceRender={true}>
              {
                <TabStatic/>
              }
            </TabPane>
          </Tabs>
          <div className="location__buttons">
            <Button name="location" type="primary" htmlType="submit">
              Save
            </Button>
          </div>
        </Form>
      }
    </>
  );
};
