import * as React from 'react';
import { Checkbox, Empty, Input } from 'antd';
import {
  useCallback, useState, Dispatch, SetStateAction, useEffect,
} from 'react';
import { DeleteOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons/lib';
import { FootprintDeviceType, FootprintType } from '../../../common/entityTypes';
import { AnswerVisitScheduleType } from '../../../common/types';

const { Search } = Input;

type FootprintListProps = {
  listFootprint?: FootprintDeviceType[],
  listVisitSchedule?: AnswerVisitScheduleType[],
  setFootprint?: Dispatch<SetStateAction<any | undefined>>,
  setListVisitSchedule?: Dispatch<SetStateAction<any | undefined>>,
  onClickItem?: () => void,
  heightArea?: string,
  notSelectedFootprint?: boolean,
  allowCheckedFootprint?: boolean,
};

type ParamButtonType = {
  add?: (e: any) => void,
  edit?: (e: any) => void,
  delete?: (e: any) => void,
};

const styleIconEdit = {
  fontSize: '20px',
  marginLeft: '15px',
};

const genExtraButton = (param: ParamButtonType) => <div>
    {
        param.edit
          ? <EditOutlined
                onClick={param.edit}
                style={{ ...styleIconEdit, color: '#e3802b' }}
            />
          : null
    }
    {
        param.add
          ? <PlusCircleOutlined
                onClick={param.add}
                style={{ ...styleIconEdit, color: '#52c41a' }}
            />
          : null
    }
    {
        param.delete
          ? <DeleteOutlined
                onClick={param.delete}
                style={{ ...styleIconEdit, color: '#f5222d' }}
            />
          : null
    }
</div>;

export const FootprintList: React.FC<FootprintListProps> = (props) => {
  const {
    listFootprint, listVisitSchedule, setFootprint, setListVisitSchedule,
    allowCheckedFootprint, heightArea, onClickItem, notSelectedFootprint,
  } = props;
  const [searchTextFootprint, setSearchTextFootprint] = useState<string>();
  const [currentFootprint, setCurrentFootprint] = useState<FootprintType>();

  const onChangeFootprint = useCallback((value: any) => {
    setSearchTextFootprint(value.target.value);
  }, []);

  useEffect(() => {
    if (setFootprint) setFootprint(currentFootprint);
  }, [currentFootprint]);

  const selectedFootprint = useCallback((e: any) => {
    if (notSelectedFootprint) return;

    const footprintId = e.target.getAttribute('footprint-id');
    const footprintSelected = listFootprint!.filter((unitFootprint) => unitFootprint.footprint_id === +footprintId)[0];
    setCurrentFootprint(footprintSelected);
  }, [listFootprint]);

  const getStyleItem = (selected?: boolean): any => {
    const styleItem = {
      padding: '10px 15px',
      cursor: !notSelectedFootprint ? 'pointer' : 'default',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      background: 'none',
      borderLeft: 'none',
      fontWeight: 'normal',
    };

    if (selected === true) {
      styleItem.background = '#fafafa';
      styleItem.borderLeft = '4px solid #e3802b';
      styleItem.borderLeft = '4px solid #e3802b';
      styleItem.fontWeight = 'bold';
    }

    return styleItem;
  };

  const deleteFootprint = useCallback((footprintId: string | undefined) => {
    console.log('footprintId', footprintId);
  }, []);

  const onChangeChekedFootprint = useCallback((e: any) => {
    const newListVisitSchedule = listVisitSchedule!.map((elem) => {
      if (e.target.id === elem.visitLineId) {
        const newElem = elem;
        newElem.doService = !elem.doService;
        return newElem;
      }
      return elem;
    });
    if (setListVisitSchedule) setListVisitSchedule(newListVisitSchedule);

    if (onClickItem) onClickItem();
  }, [listVisitSchedule]);

  return <div style={{
    border: '1px solid rgb(217, 217, 217)', minHeight: '292px', maxHeight: heightArea || 'auto', overflow: 'auto',
  }}>
        {
          listFootprint!.length
            ? <Search
                    placeholder="input search footprint"
                    onChange={onChangeFootprint}
                    style={{ width: 'calc(100% - 10px * 2)', margin: '10px' }}
                    value={searchTextFootprint}
                />
            : null
        }
        {
          listFootprint!.length
            ? listFootprint!.filter((elem) => !elem.container_footprint_name || elem.container_footprint_name.toLowerCase().includes(searchTextFootprint ? searchTextFootprint.toLowerCase() : '')).map((unitFootprint) => {
              let indexVisitSchedule;
              let disabled;
              let checked;
              if (allowCheckedFootprint) {
                indexVisitSchedule = listVisitSchedule!.findIndex((elem) => elem.visitLineId === unitFootprint.partner_footprint_id);
                disabled = indexVisitSchedule === -1;
                checked = !disabled && listVisitSchedule![indexVisitSchedule].doService;
              }
              return <div
                    key={`flp_${unitFootprint.footprint_id}`}
                    footprint-id={unitFootprint.footprint_id!.toString()}
                    onClick={selectedFootprint}
                    style={getStyleItem(currentFootprint && currentFootprint.footprint_id === unitFootprint.footprint_id)}
                >
                  {allowCheckedFootprint ? <div key={unitFootprint.partner_footprint_id}><Checkbox onChange={onChangeChekedFootprint}
                                                           disabled={disabled}
                                                           checked={checked}
                                                           className={'dshfkdsfh' }
                                                           id={unitFootprint.partner_footprint_id}
                  >{`${unitFootprint.partner_footprint_id}`}</Checkbox></div> : `${unitFootprint.partner_footprint_id}`}
                  {
                    currentFootprint && currentFootprint.footprint_id === unitFootprint.footprint_id
                      ? genExtraButton({
                        delete: (e: any) => {
                          e.stopPropagation();
                          deleteFootprint(unitFootprint.footprint_id!.toString());
                        },
                      })
                      : null
                  }
                </div>;
            })
            : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
        }
    </div>;
};
