import { Space } from 'antd';
import styled from 'styled-components';

type SpaceWrapperStyleType = {
  padding?: string;
};

export const SpaceWrapperStyle = styled(Space)<SpaceWrapperStyleType>`
  width: 100%;
  height: 100%;
  gap: 0 !important;
  padding: ${(props) => props.padding || '10px 12px 10px 10px'};
`;
