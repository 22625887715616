import {
  Button,
  DatePicker, Select, Radio, Table, Typography, RadioChangeEvent, Spin, Input, Space,
} from 'antd';
import { TablePaginationConfig } from 'antd/es/table';
import { TextAreaRef } from 'antd/es/input/TextArea';
import React, {
  ChangeEvent, Ref, useCallback, useEffect, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Translate } from 'i18n-next-tools';
import { fetchOperators } from '../../../store/slices/operatorSlice';
import { AppDispatch, RootState } from '../../../store';
import {
  CustomerType,
  DeviceType, FootprintType, LocationType, OperatorType, WarehouseType,
} from '../../../../../common/entityTypes';
import {
  clearDeviceIssueMessage,
  fetchDeviceListByOperator,
  fetchDeviceListByOperatorWithFootprints, moveDevicesBetweenFootprints,
} from '../../../store/slices/deviceSlice';
import { fetchFootprintList } from '../../../store/slices/footprintSlice';
import {
  customersQueryIds,
  dateForDB,
  handlePageSize,
  locationsQuery,
} from '../../../common';
import { fetchWarehouseHistoryByDevice, fetchWarehouses } from '../../../store/slices/warehouseSlice';
import {
  deviceRowSelection, footprintrRowSelection, getDeviceColumns, getFootprintColumns,
} from '../config';
import { addSpinnerItem, removeSpinnerItem } from '../../../store/slices/spinnerSlice';
import { fetchLocationsById } from '../../../store/slices/locationSlice';
import { fetchCustomersByIds } from '../../../store/slices/customerSlice';
import { PAGINATION_TABLE } from '../../../consts';

const { Option } = Select;
const { Text, Title } = Typography;

const t = Translate('MoveDevicesPage');

export const MoveDevice = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [disableMovement, setDisableMovement] = useState<boolean>(false);
  const [operatorFrom, setOperatorFrom] = useState<number | undefined>(undefined);
  const [destination, setDestination] = useState<string>('footprint');
  const [selectedRowDeviceKeys, setSelectedRowDeviceKeys] = useState<React.Key[]>([]);
  const [effectiveDate, setEffectiveDate] = useState<string>();
  const [filteredDevicesTextArea, setFilteredDevicesTextArea] = useState<string>();
  const [filteredDevices, setFilteredDevices] = useState<DeviceType[]>([]);
  const [filteredFootprintsTextArea, setFilteredFootprintsTextArea] = useState<string>();
  const [filteredFootprints, setFilteredFootprints] = useState<FootprintType[]>([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState<number | undefined>(undefined);
  const [selectedRowFootprintKeys, setSelectedRowFootprintKeys] = useState<React.Key[]>([]);
  const operatorList = useSelector<RootState, OperatorType[]>((store) => store.operator.operatorList);
  const deviceListByOperator = useSelector<RootState, DeviceType[]>((store) => store.device.deviceListByOperator);
  const footprintList = useSelector<RootState, FootprintType[]>((store) => store.footprint.footprintList);
  const warehouseList = useSelector<RootState, WarehouseType[]>((store) => store.warehouse.warehouseList);
  const locationList = useSelector<RootState, LocationType[]>((store) => store.location.locationList);
  const customerList = useSelector<RootState, CustomerType[]>((store) => store.customer.customerList);
  const moveDeviceResponse = useSelector<RootState, {
    message: string,
    error: boolean
  }>((store) => store.device.deviceIssueMessage);
  const spinner = useSelector<RootState, boolean>((store) => store.spinner.spinner);
  const filterDeviceRef = useRef() as any as Ref<TextAreaRef> | undefined;

  const [paginationDeviceTable, setPaginationDeviceTable] = useState<TablePaginationConfig>({
    ...PAGINATION_TABLE,
    pageSize: handlePageSize({ place: 'move_device_devices' }),
  });
  const [paginationFootprintTable, setPaginationFootprintTable] = useState<TablePaginationConfig>({
    ...PAGINATION_TABLE,
    pageSize: handlePageSize({ place: 'move_device_footprints' }),
  });

  const handleFootprintTableChange = useCallback((pagination) => {
    setPaginationFootprintTable(pagination);
    handlePageSize({ place: 'move_device_footprints', pageSize: pagination.pageSize });
  }, []);

  const handleDeviceTableChange = useCallback((pagination) => {
    setPaginationDeviceTable(pagination);
    handlePageSize({ place: 'move_device_devices', pageSize: pagination.pageSize });
  }, []);

  const handleSelectOperatorFrom = (value: number) => {
    setOperatorFrom(value);
  };

  useEffect(() => {
    dispatch(fetchOperators());
    dispatch(fetchWarehouses());
  }, []);

  useEffect(() => {
    if (operatorFrom
      && effectiveDate
      && selectedRowDeviceKeys.length
      && (selectedRowFootprintKeys.length || selectedWarehouse)
    ) {
      setDisableMovement(false);
    } else {
      setDisableMovement(true);
    }
    dispatch(clearDeviceIssueMessage());
  }, [operatorFrom, effectiveDate, selectedRowDeviceKeys, selectedRowFootprintKeys, selectedWarehouse]);

  useEffect(() => {
    if (operatorFrom) {
      dispatch(fetchFootprintList(operatorFrom));
      dispatch(fetchDeviceListByOperator(operatorFrom));
    }
  }, [operatorFrom]);

  useEffect(() => {
    if (deviceListByOperator.length) setFilteredDevices(deviceListByOperator);
  }, [deviceListByOperator]);

  useEffect(() => {
    if (footprintList.length) {
      setFilteredFootprints(footprintList);
      (async () => {
        dispatch(addSpinnerItem('move_device'));
        const locationListIds: any = {};
        footprintList.forEach((footprint) => {
          if (footprint.location_id) locationListIds[`${footprint.location_id}`] = footprint.location_id;
        });
        console.log('locationListIds', locationListIds);
        await dispatch(fetchLocationsById(locationsQuery(Object.keys(locationListIds).map((location_id) => ({
          location_id: Number(location_id),
          route_id: 0,
        }))).join('&')));
        dispatch(removeSpinnerItem('move_device'));
      })();
    }
  }, [footprintList]);

  useEffect(() => {
    if (locationList.length) {
      (async () => {
        dispatch(addSpinnerItem('move_device'));
        const customersIds: any = {};
        locationList.forEach((location) => {
          customersIds[`${location.customer_id}`] = location.customer_id;
        });
        dispatch(fetchCustomersByIds(customersQueryIds(Object.keys(customersIds)).join('&')));
        dispatch(removeSpinnerItem('move_device'));
      })();
    }
  }, [locationList]);

  useEffect(() => {
    if (deviceListByOperator.length) {
      const devicesId = deviceListByOperator.map((device) => `device_id=${device.device_id}`);
      const devicesIdString = devicesId.join('&');
      dispatch(fetchWarehouseHistoryByDevice(devicesIdString));
    }
  }, [deviceListByOperator]);

  const onSelectDeviceChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowDeviceKeys(newSelectedRowKeys);
  };

  const onSelectFootprintChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowFootprintKeys(newSelectedRowKeys);
  };

  const onChangeEffectiveDate = useCallback(async (_, dateString: string) => {
    setEffectiveDate(dateString);
  }, []);

  const handleMove = async () => {
    console.log('move');
    dispatch(addSpinnerItem('move_device'));
    const timestamp: string = dateForDB(effectiveDate as any as Date);
    await dispatch(moveDevicesBetweenFootprints({
      device_id: Number(selectedRowDeviceKeys[0]),
      footprint_id: Number(selectedRowFootprintKeys[0]),
      effective_datetime: timestamp!,
      warehouse_id: selectedWarehouse!,
    }));
    if (operatorFrom && !moveDeviceResponse.error) {
      await dispatch(fetchFootprintList(operatorFrom));
      await dispatch(fetchDeviceListByOperatorWithFootprints(operatorFrom));
      // @ts-ignore
      filterDeviceRef?.current.focus();
      setFilteredDevicesTextArea('');
      setFilteredFootprintsTextArea('');
      setFilteredDevices(deviceListByOperator);
      setFilteredFootprints(footprintList);
      setSelectedRowFootprintKeys([]);
      setSelectedRowDeviceKeys([]);
    }
    dispatch(removeSpinnerItem('move_device'));
  };

  const handleSelectWarehouse = (value: number) => {
    setSelectedWarehouse(value);
  };

  const changeDestination = (event: RadioChangeEvent) => {
    setDestination(event.target.value);
    setSelectedWarehouse(undefined);
    setSelectedRowFootprintKeys([]);
  };

  const handleFilterDevices = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setFilteredDevicesTextArea(event.target.value);
    const splitValue = event.target.value.split(' ');
    let filtered: DeviceType[] = deviceListByOperator;

    for (let i = 0; i < splitValue.length; i++) {
      filtered = filtered.filter((device) => {
        const deviceCondition = device.device_number.toLowerCase().includes(splitValue[i].toLowerCase());

        if (deviceCondition) return device;

        return false;
      });
    }

    if (filtered.length === 1 && filtered[0].device_id) {
      setSelectedRowDeviceKeys([filtered[0].device_id]);
    } else {
      setSelectedRowDeviceKeys([]);
    }

    setFilteredDevices(filtered);
  };

  const handleFilterFootprints = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setFilteredFootprintsTextArea(event.target.value);
    const splitValue = event.target.value.split(' ');
    let filtered: FootprintType[] = footprintList;

    for (let i = 0; i < splitValue.length; i++) {
      filtered = filtered.filter((footprint) => {
        const location = locationList.find((loc) => loc.location_id === footprint.location_id);
        let customer: CustomerType | undefined;

        if (location) customer = customerList.find((cust) => cust.customer_id === location.customer_id);

        const footprintCondition = footprint.container_footprint_name?.toLowerCase().includes(splitValue[i].toLowerCase());
        const locationCondition = location && (location.address1?.toLowerCase().includes(splitValue[i].toLowerCase()) || location.city?.toLowerCase().includes(splitValue[i].toLowerCase()) || location.zip?.toLowerCase().includes(splitValue[i].toLowerCase()) || location.state_province?.toLowerCase().includes(splitValue[i].toLowerCase()));
        const customerCondition = customer && customer.customer_name.toLowerCase().includes(splitValue[i].toLowerCase());

        if (footprintCondition || locationCondition || customerCondition) return footprint;

        return false;
      });
    }

    if (filtered.length === 1 && filtered[0].footprint_id) {
      setSelectedRowFootprintKeys([filtered[0].footprint_id]);
    } else {
      setSelectedRowFootprintKeys([]);
    }

    setFilteredFootprints(filtered);
  };

  return (
    <>
      <Space direction={'horizontal'} align={'center'} style={{ justifyContent: 'space-between', width: '100%' }}>
        <Title level={3}>{t.k1('Move Devices')}</Title>
      </Space>
      <Spin spinning={spinner}>
        <div className="move-device">
          <div className="move-device__header">
            <div className="move-device__header-box">
              <Text className="move-device__text">Select operator</Text>
              <Select
                onChange={handleSelectOperatorFrom}
                allowClear={true}
                placeholder={'Select operator'}
                value={operatorFrom}
                className="move-device__input"
              >
                {operatorList
                  .map((operator) => (
                    <Option
                      key={operator.operator_id}
                      value={operator.operator_id!}
                    >
                      {operator.operator_name}
                    </Option>
                  ))}
              </Select>
            </div>
            <div className="move-device__header-box">
              <Text className="move-device__text">Select effective date</Text>
              <DatePicker
                showTime={{ format: 'HH:mm' }}
                format="YYYY-MM-DD HH:mm"
                className="move-device__input"
                onChange={onChangeEffectiveDate}
              />
            </div>
            <div className="move-device__header-box">
              <Radio.Group defaultValue={'footprint'} className="move-device__radio-group" onChange={changeDestination}>
                <Radio value={'footprint'}>Footprint</Radio>
                <Radio value={'warehouse'}>Warehouse</Radio>
              </Radio.Group>
            </div>
            <div className="move-device__header-box move-device__header-box-action">
              <Button
                disabled={disableMovement}
                onClick={handleMove}
              >
                Move device
              </Button>
            </div>
          </div>
          <div className="move-device__main">
            <div className="move-device__main-box">
              <Text style={{ fontSize: 12 }}>Past device number from a new line</Text>
              <Input.TextArea
                tabIndex={1}
                ref={filterDeviceRef}
                onChange={handleFilterDevices}
                className="ship-device__input"
                placeholder="Filter by device number"
                disabled={!deviceListByOperator.length}
                value={filteredDevicesTextArea}
                autoSize={{ minRows: 3 }}
              />
              <Text className="move-device__text">Devices</Text>
              <Table
                pagination={paginationDeviceTable}
                onChange={handleDeviceTableChange}
                rowSelection={deviceRowSelection(selectedRowDeviceKeys, onSelectDeviceChange)}
                columns={getDeviceColumns(warehouseList)}
                dataSource={filteredDevices}
                locale={{
                  emptyText: '---',
                }}
              />
            </div>
            <div className="move-device__main-box">
              {destination === 'warehouse'
                ? <>
                  <Text className="move-device__text">Warehouses</Text>
                  <Select
                    className="move-device__input"
                    onChange={handleSelectWarehouse}
                    allowClear={true}
                    placeholder={'Select warehouse'}
                    value={selectedWarehouse}
                  >
                    {warehouseList
                      .filter((warehouse) => warehouse.operator_id === operatorFrom)
                      .map((warehouse) => (
                        <Option
                          key={warehouse.warehouse_id}
                          value={warehouse.warehouse_id!}
                        >
                          {warehouse.warehouse_name}
                        </Option>
                      ))}
                  </Select>
                  {moveDeviceResponse.error && <>
                    <Text type="danger">{moveDeviceResponse.message}</Text>
                  </>}
                </>
                : <>
                  <Text style={{ fontSize: 12 }}>Past container footprint name from a new line</Text>
                  <Input.TextArea
                    tabIndex={2}
                    onPressEnter={(e) => {
                      handleMove();
                      e.preventDefault();
                    }}
                    onChange={handleFilterFootprints}
                    className="ship-device__input"
                    placeholder="Filter by container footprint name"
                    disabled={!deviceListByOperator.length}
                    value={filteredFootprintsTextArea}
                    autoSize={{ minRows: 3 }}
                  />
                  <Text className="move-device__text">Footprints</Text>
                  <Table
                    onChange={handleFootprintTableChange}
                    pagination={paginationFootprintTable}
                    rowSelection={footprintrRowSelection(selectedRowFootprintKeys, onSelectFootprintChange)}
                    columns={getFootprintColumns(deviceListByOperator, locationList, customerList)}
                    dataSource={filteredFootprints}
                    locale={{
                      emptyText: '---',
                    }}
                  />
                </>
              }
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
};
