import { Link } from 'react-router-dom';
import { ColumnsType } from 'antd/es/table';
import { RoutesInfoListType } from '../../../store/slices/routeSlice';
import { paths } from '../../../common';

export const getColumns = (): ColumnsType<RoutesInfoListType> => [
  {
    title: 'Route Name',
    dataIndex: 'route_name',
    key: 'route_name',
    sorter: (a: any, b: any) => a.route_name.localeCompare(b.route_name),
    render: (value, raw) => <Link className="table__link"
                                  to={`${paths.ROUTE_DETAIL}?rid=${raw.route_id}`}>{value}</Link>,
  },
  {
    title: 'Warehouse',
    dataIndex: 'warehouse',
    key: 'warehouse',
    render: (value) => value[0].warehouse_name,
  },
  {
    title: 'Locations number',
    dataIndex: 'customers_and_locations',
    key: 'customers_and_locations',
    className: 'editable-row',
    render: (value, raw) => raw.routeInfo.length,
  },
];
