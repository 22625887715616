import React, {
  ReactNode, useCallback, useEffect, useState,
} from 'react';
import {
  Button, notification, Spin, Table,
} from 'antd';
import { NotificationPlacement } from 'antd/es/notification';
import { CheckCircleFilled } from '@ant-design/icons';
import { TablePaginationConfig } from 'antd/es/table';
import { useDispatch, useSelector } from 'react-redux';
import { addSpinnerItem, removeSpinnerItem } from '../../../store/slices/spinnerSlice';
import {
  createRoute, fetchRoutes, fetchRoutesInfo, RoutesInfoListType,
} from '../../../store/slices/routeSlice';
import { AppDispatch, RootState } from '../../../store';
import { OperatorType, WarehouseType } from '../../../../../common/entityTypes';
import { PAGINATION_TABLE } from '../../../consts';
import { getColumns } from '../config/tableConfig';
import { fetchWarehouses } from '../../../store/slices/warehouseSlice';
import { RouteModal } from '../../RouteSchedulePage/components/routeModal';
import { fetchLocationsById } from '../../../store/slices/locationSlice';

export const RoutePage = () => {
  const dispatch = useDispatch<AppDispatch>();

  const [selectedWarehouse, setSelectedWarehouse] = useState<number | undefined>(undefined);
  const [isRouteModalVisible, setIsRouteModalVisible] = useState(false);
  const [paginationTable, setPaginationTable] = useState<TablePaginationConfig>(PAGINATION_TABLE);
  const handleTableChange = useCallback((pagination) => {
    setPaginationTable(pagination);
  }, []);
  const currentOperator = useSelector<RootState, OperatorType | null>((store) => store.operator.currentOperator);
  const spinner = useSelector<RootState, boolean>((store) => store.spinner.spinner);
  const routesInfoList = useSelector<RootState, RoutesInfoListType[]>((store) => store.route.routesInfoList);
  const warehouseList = useSelector<RootState, WarehouseType[]>((store) => store.warehouse.warehouseList);
  const [api, contextHolder] = notification.useNotification();
  const successIcon = <CheckCircleFilled style={{ color: 'green' }}/>;
  const openNotification = (placement: NotificationPlacement, message: string, desc: string, icon: ReactNode) => {
    api.info({
      message,
      description: desc,
      placement,
      icon,
    });
  };

  useEffect(() => {
    (async () => {
      dispatch(addSpinnerItem('route_schedule'));
      await dispatch(fetchRoutesInfo(currentOperator?.operator_id!));
      dispatch(removeSpinnerItem('route_schedule'));
    })();
  }, []);

  const showRouteModal = () => {
    (async () => {
      dispatch(addSpinnerItem('route_schedule'));
      await dispatch(fetchWarehouses());
      dispatch(removeSpinnerItem('route_schedule'));
      setIsRouteModalVisible(true);
    })();
  };

  const handleCancelRouteModal = () => {
    setIsRouteModalVisible(false);
  };

  const handleRouteCreate = async (values: any) => {
    dispatch(addSpinnerItem('route_schedule'));
    await dispatch(createRoute(
      {
        ...values,
        operator_id: currentOperator?.operator_id,
      },
    ));
    await dispatch(fetchRoutes());
    handleCancelRouteModal();
    openNotification('topRight', 'Route created', '', successIcon);
    dispatch(removeSpinnerItem('route_schedule'));
  };

  const handleWarehouse = async (value: number) => {
    setSelectedWarehouse(value);
    dispatch(addSpinnerItem('route_schedule'));
    await dispatch(fetchLocationsById(`warehouse_id=${value}`));
    dispatch(removeSpinnerItem('route_schedule'));
  };

  return (
    <div>
      <Spin spinning={spinner} tip={'please wait ...'}>
        <h1>Routes</h1>
        {contextHolder}
        <RouteModal
          spinner={spinner}
          isRouteModalVisible={isRouteModalVisible}
          handleCancelRouteModal={handleCancelRouteModal}
          handleRouteCreate={handleRouteCreate}
          selectedWarehouse={selectedWarehouse}
          handleWarehouse={handleWarehouse}
          warehouseList={warehouseList}
        />
        <Button onClick={showRouteModal}>Create route</Button>
        <Table
          columns={getColumns()}
          className={'click_row'}
          dataSource={routesInfoList}
          pagination={paginationTable}
          onChange={handleTableChange}
        />
      </Spin>
    </div>
  );
};
