import React, {
} from 'react';
import {
  Checkbox,
  Form, InputNumber,
} from 'antd';
import '../styles/LocationMainPage.scss';

export const TabDynamic = () => {
  console.log();
  return (
    <>
      <Form.Item
          label="Fill trigger"
          name={'fill_trigger_percent'}
          rules={[
            { required: true, message: 'Please input fill trigger!' },
          ]}
      >
        <InputNumber max={100} min={0} />
      </Form.Item>
      <Form.Item
          name={'trigger_service'}
          valuePropName="checked"
      >
        <Checkbox onChange={() => {}}>
          Trigger service for single container in group
        </Checkbox>
      </Form.Item>
      <Form.Item
          label="Distance between container in group"
          name={'distance_between_container_in_group'}
      >
          <InputNumber max={20} min={0} />
      </Form.Item>
      <Form.Item
          label="Max container days without visit"
          name={'max_day_without_visit'}
      >
        <InputNumber max={100} min={0} />
      </Form.Item>
    </>
  );
};
