import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { ERequestRoute, ERequestUrl } from '../config';
import { LocationFootprintDeviceType, ScheduleSettingType, LocationType } from '../../../../common/entityTypes';
import {
  DEFAULT_DAY_WEEK,
  DEFAULT_DAYS_BETWEEN_SERVICE, DEFAULT_DISTANCE_CONTAINER_IN_GROUP,
  DEFAULT_FILL_TRIGGER_PERCENT, DEFAULT_FLEXIBILITY,
  DEFAULT_MAX_DAY_WITHOUT_VISIT, DEFAULT_RECURRENCE,
  DEFAULT_TRIGGER_SERVICE,
} from '../../common/web_consts';

const initialLocation: LocationType = {
  location_id: 0,
  customer_id: null!,
  region_id: null!,
  partner_location_id: '',
  location_name: '',
  warehouse_id: null!,
  address1: '',
  address2: '',
  city: '',
  state_province: '',
  zip: '',
  country: '',
  schedule_id: null!,
  cycle_id: null!,
  timezone: '',
  days_open: '',
  exception_period: '',
  permanent_location_notes: '',
};

export const LocationModalTypeOption = {
  ADD_NEW: 'Add new location',
  EDIT: 'Edit location',
};

export interface ILocationState{
  initialLocation: LocationType,
  locationFootprintList: LocationFootprintDeviceType[],
  locationList: LocationType[],
  locationLoader: boolean,
  locationModalType: string,
  location: LocationType | null
  locationScheduleSetting: ScheduleSettingType | null
}

const initialState: ILocationState = {
  initialLocation,
  location: null,
  locationScheduleSetting: null,
  locationFootprintList: [],
  locationLoader: false,
  locationList: [],
  locationModalType: LocationModalTypeOption.ADD_NEW,
};

export const fetchLocationFootprint = createAsyncThunk('locationFootprintList/fetch', async (filters?: string) => {
  const response = await axios.get<LocationFootprintDeviceType[]>(`${ERequestUrl.AURORA}/${ERequestRoute.LOCATION_FOOTPRINT_LIST}?${filters}`);
  return response.data.map((row, index) => ({ ...row, key: index }));
});

export const fetchLocations = createAsyncThunk('locationList/fetch', async () => {
  const response = await axios.get<LocationType[]>(`${ERequestUrl.BASE_CRUD}/${ERequestRoute.LOCATION}`);
  return response.data;
});

export const fetchLocationsById = createAsyncThunk('locationListById/fetch', async (locationIds: string) => {
  const response = await axios.get<LocationType[]>(`${ERequestUrl.BASE_CRUD}/${ERequestRoute.LOCATION}?${locationIds}`);
  return response.data;
});

export const fetchLocation = createAsyncThunk('fetchLocation/fetch', async (location_id: string) => {
  const response = await axios.get<LocationType[]>(`${ERequestUrl.BASE_CRUD}/${ERequestRoute.LOCATION}?location_id=${location_id}`);
  return response.data;
});

export const addLocation = createAsyncThunk('location/add', async (payload: LocationType) => {
  const response = await axios.post<LocationType[]>(`${ERequestUrl.BASE_CRUD}/${ERequestRoute.LOCATION}`, JSON.stringify(payload));
  return response.data;
});

export const updateLocation = createAsyncThunk('location/update', async (payload: LocationType) => {
  const response = await axios.post<LocationType[]>(`${ERequestUrl.BASE_CRUD}/${ERequestRoute.LOCATION}/${payload.location_id}`, JSON.stringify(payload));
  return response.data;
});

export const fetchLocationScheduleSetting = createAsyncThunk('fetchLocationScheduleSetting/fetch', async (location_id: string) => {
  const response = await axios.get<ScheduleSettingType[]>(`${ERequestUrl.BASE_CRUD}/${ERequestRoute.SCHEDULE_SETTING}?location_id=${location_id}`);
  return response.data;
});

export const addLocationScheduleSetting = createAsyncThunk('locationScheduleSetting/add', async (payload: ScheduleSettingType) => {
  const response = await axios.post<ScheduleSettingType[]>(`${ERequestUrl.BASE_CRUD}/${ERequestRoute.SCHEDULE_SETTING}`, JSON.stringify(payload));
  return response.data;
});

export const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setLocationLoader: (state, action: PayloadAction<boolean>) => {
      state.locationLoader = action.payload;
    },
    setInitialLocation: (state, action: PayloadAction<LocationType>) => {
      state.initialLocation = action.payload;
    },
    setLocationModalType: (state, action: PayloadAction<string>) => {
      state.locationModalType = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLocationFootprint.pending, (state) => {
      state.locationLoader = true;
    });
    builder.addCase(fetchLocationFootprint.fulfilled, (state, action: PayloadAction<LocationFootprintDeviceType[]>) => {
      state.locationFootprintList = action.payload;
      state.locationLoader = false;
    });
    builder.addCase(fetchLocations.fulfilled, (state, action: PayloadAction<LocationType[]>) => {
      state.locationList = action.payload;
    });
    builder.addCase(fetchLocationsById.fulfilled, (state, action: PayloadAction<LocationType[]>) => {
      state.locationList = action.payload;
    });
    builder.addCase(fetchLocation.fulfilled, (state, action: PayloadAction<LocationType[]>) => {
      state.location = { ...action.payload[0] };
    });
    builder.addCase(fetchLocationScheduleSetting.fulfilled, (state, action: PayloadAction<ScheduleSettingType[]>) => {
      if (!action.payload.length) {
        state.locationScheduleSetting = {
          fill_trigger_percent: DEFAULT_FILL_TRIGGER_PERCENT,
          max_day_without_visit: DEFAULT_MAX_DAY_WITHOUT_VISIT,
          distance_between_container_in_group: DEFAULT_DISTANCE_CONTAINER_IN_GROUP,
          trigger_service: DEFAULT_TRIGGER_SERVICE,
          days_between_service: DEFAULT_DAYS_BETWEEN_SERVICE,
          flexibility: DEFAULT_FLEXIBILITY,
          recurrence: DEFAULT_RECURRENCE,
          week_1: DEFAULT_DAY_WEEK,
          week_2: undefined,
          week_3: undefined,
          week_4: undefined,
        };
        return;
      }

      const listScheduleSettings = [...action.payload].sort((scheduleSetting_1, scheduleSetting_2) => scheduleSetting_2.schedule_setting_id! - scheduleSetting_1.schedule_setting_id!);
      const scheduleSetting = listScheduleSettings?.[0];
      console.log(listScheduleSettings);
      state.locationScheduleSetting = { ...scheduleSetting };
    });
  },
});

// Action creators are generated for each case reducer function
// export const {} = counterSlice.actions;

export const {
  setInitialLocation,
  setLocationModalType,
} = locationSlice.actions;

export default locationSlice.reducer;
