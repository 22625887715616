import { Link } from 'react-router-dom';
import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { CustomerType } from '../../../../../common/entityTypes';
import { paths } from '../../../common';

export const columns: ColumnsType<CustomerType> = [
  {
    title: 'Name',
    dataIndex: 'customer_name',
    render: (value, raw) => {
      const params = [
        `customer=${raw.customer_id}`,
        'rowlimit=100',
      ];
      const link = `${paths.CUSTOMER_DETAIL}?${params.join('&')}`;
      return <Link className="table__link" to={link}>{value}</Link>;
    },
  },
  {
    title: 'Address 1',
    dataIndex: 'address1',
  },
  {
    title: 'Address 2',
    dataIndex: 'address2',
  },
  {
    title: 'City',
    dataIndex: 'city',
    filters: [
      {
        text: 'Dnipro',
        value: 'Dnipro',
      },
      {
        text: 'New York',
        value: 'New York',
      },
    ],
  },
  {
    title: 'State Province',
    dataIndex: 'state_province',
  },
  {
    title: 'Zip',
    dataIndex: 'zip',
  },
  {
    title: 'Country',
    dataIndex: 'country',
  },
];
