import * as React from 'react';
import { Menu } from 'antd';
import { useHistory } from 'react-router';
import {
  Key, useCallback, useState,
} from 'react';

const { SubMenu } = Menu;

export type MenuItem = {
  title: string,
  path: string,
};

export type MenuSubItem = {
  title: string,
  items: MenuItem[],
  path: string,
};

type MenuSystemType = {
  item: MenuSubItem[],
  subItemsMenu: MenuItem[],
  path: string,
  parentPath: string,
};

export const MenuSystem: React.FC<MenuSystemType> = (props) => {
  const { item, subItemsMenu } = props;
  const history = useHistory();
  const [actualPathParent, setActualPathParent] = useState(props.parentPath);
  const [actualPath, setActualPath] = useState(`${props.parentPath}${props.path}`);
  const [classOpenedSubMenu, setClassOpenedSubMenu] = useState('');

  const renderItemMenu = useCallback((pathParent: string, items: MenuItem[], onclick: any) => (
    items.map((subItem, index) => (<Menu.Item
        key={`${pathParent}${subItem.path}` || `${subItem.title.replace(/ /, '_')}:${index}`}
        onClick={onclick}
      >
        {subItem.title}
      </Menu.Item>
    ))
  ), []);

  const onClickItemMenu = useCallback((e: any) => {
    history.push(e.key, history.location.pathname);
    const [aPath, aPathParent] = e.keyPath;
    console.log('click to item menu', aPath, aPathParent);
    setActualPath(aPath);
    if (aPathParent) setActualPathParent(aPathParent);
  }, []);

  const RenderMenu = () => item.map((subItem) => (
    subItem.items.length
      ? <SubMenu key={`${subItem.path}`} title={subItem.title} popupClassName={'open_sub_menu'}>
        {
          renderItemMenu(subItem.path, subItem.items, onClickItemMenu)
        }
      </SubMenu>
      : renderItemMenu('', [subItem], onClickItemMenu)
  ));

  const onOpenSubMenu = (openKeys: Key[]) => {
    console.log(openKeys);
    setClassOpenedSubMenu(classOpenedSubMenu ? '' : 'submenu_opened');
  };

  return (
    <>
      <Menu
        mode="horizontal"
        className={`menu_first_level ${classOpenedSubMenu}`}
        defaultSelectedKeys={[actualPathParent, actualPath]}
        triggerSubMenuAction={'click'}
        onOpenChange={onOpenSubMenu}
      >
        {RenderMenu()}
      </Menu>
      {
        subItemsMenu.length
          ? <Menu mode="horizontal" className={`menu_second_level ${classOpenedSubMenu}`} selectedKeys={[actualPath]}>
            {
              renderItemMenu(actualPathParent, subItemsMenu, onClickItemMenu)
            }
          </Menu>
          : <></>
      }
    </>
  );
};
