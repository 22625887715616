import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { ERequestRoute, ERequestUrl } from '../config';
import {
  RouteLocationType,
  RouteScheduleFootprintType, RouteScheduleLocationType,
  RouteScheduleType,
  RouteType,
  CustomerType,
  LocationType, WarehouseType,
} from '../../../../common/entityTypes';

export interface RoutesInfoListType extends RouteType {
  warehouse: WarehouseType,
  routeInfo: {
    location: LocationType,
    customer: CustomerType
  }[]
}

export interface IRouteState {
  routeList: RouteType[];
  routesInfoList: RoutesInfoListType[];
  routeScheduleFootprintList: RouteScheduleFootprintType[];
  routeScheduleList: RouteScheduleType[];
  routeLocationList: RouteLocationType[];
  routeScheduleLocationList: RouteScheduleLocationType[];
}

const initialState: IRouteState = {
  routeList: [],
  routesInfoList: [],
  routeScheduleFootprintList: [],
  routeScheduleList: [],
  routeLocationList: [],
  routeScheduleLocationList: [],
};

export const fetchRoutes = createAsyncThunk('routeList/fetch', async (operators?: string) => {
  const response = await axios.get<RouteType[]>(`${ERequestUrl.BASE_CRUD}/${ERequestRoute.ROUTE}?${operators || ''}`);
  return response.data;
});

export const fetchRoutesById = createAsyncThunk('routeListById/fetch', async (ids?: string) => {
  const response = await axios.get<RouteType[]>(`${ERequestUrl.BASE_CRUD}/${ERequestRoute.ROUTE}?${ids}`);
  return response.data;
});

export const fetchRoutesInfo = createAsyncThunk('routeInfoList/fetch', async (operatorId: number) => {
  const response = await axios.get<RoutesInfoListType[]>(`${ERequestUrl.BASE}/${ERequestRoute.ROUTES_INFO}/${operatorId}`);
  return response.data;
});

export const fetchRouteScheduleFootprint = createAsyncThunk('routeScheduleFootprint/fetch', async ({ route_schedule_id }: {
  route_schedule_id: number
}) => {
  const response = await axios.get<RouteScheduleFootprintType[]>(`${ERequestUrl.BASE_CRUD}/${ERequestRoute.ROUTE_SCHEDULE_FOOTPRINT}?route_schedule_id=${route_schedule_id}`);
  return response.data;
});

export const fetchRouteSchedule = createAsyncThunk('routeSchedule/fetch', async ({ route_id }: {
  route_id: number
}) => {
  const response = await axios.get<RouteScheduleType[]>(`${ERequestUrl.BASE_CRUD}/${ERequestRoute.ROUTE_SCHEDULE}?route_id=${route_id}`);
  return response.data;
});
export const fetchRouteLocations = createAsyncThunk('routeLocations/fetch', async ({ route_id }: {
  route_id: number
}) => {
  const response = await axios.get<RouteLocationType[]>(`${ERequestUrl.BASE_CRUD}/${ERequestRoute.ROUTE_LOCATION}?route_id=${route_id}`);
  return response.data;
});

export const createRoute = createAsyncThunk('route/create', async (payload: any) => {
  const response = await axios.post(`${ERequestUrl.BASE}/${ERequestRoute.ADD_ROUTE}`, JSON.stringify(payload));
  return response.data;
});

export const createRouteSchedule = createAsyncThunk('routeSchedule/create', async (payload: any) => {
  const response = await axios.get(`${ERequestUrl.BASE}/${ERequestRoute.CREATE_SCHEDULE}/${payload.route_name}/${payload.schedule_datetime}`);
  return response.data;
});

export const updateRouteScheduleFootprint = createAsyncThunk('routeScheduleFootprint/update', async (payload: any) => {
  const response = await axios.post(`${ERequestUrl.BASE}/schedule/DEMO_BIN_BOT/24-01-2023`, JSON.stringify(payload));
  return response.data;
});

export const updateScheduleLocationOrder = createAsyncThunk('scheduleLocationOrder/save', async (payload: any) => {
  const response = await axios.post(`${ERequestUrl.BASE}/${ERequestRoute.SAVE_ROUTE_SCHEDULE_LOCATION_ORDER}`, JSON.stringify(payload));
  return response.data;
});

export const fetchScheduleLocationOrderByScheduleId = createAsyncThunk('scheduleLocationOrder/fetch', async ({ route_schedule_id }: {
  route_schedule_id: number
}) => {
  const response = await axios.get(`${ERequestUrl.BASE_CRUD}/${ERequestRoute.ROUTE_SCHEDULE_LOCATION}?route_schedule_id=${route_schedule_id}`);
  return response.data;
});

export const routeSlice = createSlice({
  name: 'route',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchRoutes.fulfilled, (state, action: PayloadAction<RouteType[]>) => {
      state.routeList = action.payload;
    });
    builder.addCase(fetchRoutesById.fulfilled, (state, action: PayloadAction<RouteType[]>) => {
      state.routeList = action.payload;
    });
    builder.addCase(fetchRouteSchedule.fulfilled, (state, action: PayloadAction<RouteScheduleType[]>) => {
      state.routeScheduleList = action.payload;
    });
    builder.addCase(fetchRouteScheduleFootprint.fulfilled, (state, action: PayloadAction<RouteScheduleFootprintType[]>) => {
      state.routeScheduleFootprintList = action.payload;
    });
    builder.addCase(fetchRouteLocations.fulfilled, (state, action: PayloadAction<RouteLocationType[]>) => {
      state.routeLocationList = action.payload;
    });
    builder.addCase(fetchScheduleLocationOrderByScheduleId.fulfilled, (state, action: PayloadAction<RouteScheduleLocationType[]>) => {
      state.routeScheduleLocationList = action.payload;
    });
    builder.addCase(fetchRoutesInfo.fulfilled, (state, action: PayloadAction<RoutesInfoListType[]>) => {
      state.routesInfoList = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
// export const {} = counterSlice.actions;

export default routeSlice.reducer;
