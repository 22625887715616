import { API } from '../../constsAPI';

export const ERequestUrl = {
  BASE_CRUD: `${API.URL_REST_API}/crud`,
  BASE: API.URL_REST_API,
  AURORA: API.URL_AURORA_DB,
};

export const ERequestRoute = {
  CUSTOMER: 'Customer',
  LOCATION: 'Location',
  SCHEDULE_SETTING: 'ScheduleSetting',
  FOOTPRINT: 'Footprint',
  REGION: 'Region',
  DEVICE: 'Device',
  SCHEDULE: 'Schedule',
  CREATE_SCHEDULE: 'schedule',
  CYCLE: 'Cycle',
  ROUTE: 'Route',
  ROUTES_INFO: 'routes',
  FLOORPLAN: 'Floorplan',
  SHIP: 'Ship',
  DRIVER_HISTORY: 'DriverHistory',
  WORK_NOTE: 'WorkNote',
  ROUTE_SCHEDULE_FOOTPRINT: 'RouteScheduleFootprint',
  ROUTE_SCHEDULE: 'RouteSchedule',
  ROUTE_LOCATION: 'RouteLocation',
  ROUTE_SCHEDULE_LOCATION: 'RouteScheduleLocation',
  SHIP_DETAIL: 'ShipDetail',
  WAREHOUSE: 'Warehouse',
  WAREHOUSE_HISTORY: 'WarehouseHistory',
  OPERATOR: 'Operator',
  DEVICE_FOOTPRINT: 'device_footprint',
  SHIP_DEVICE: 'ship_device',
  ADD_ROUTE: 'add_route',
  MOVE_DEVICE: 'move_device_between_footprints',
  LOCATION_FOOTPRINT_LIST: 'location_footprint_list',
  FOOTPRINT_LIST_BY_OPERATOR: 'footprint_list_by_operator',
  SAVE_ROUTE_SCHEDULE_LOCATION_ORDER: 'save_route_schedule_location_order',
};
