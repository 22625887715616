import React, { useCallback, useEffect, useState } from 'react';
import {
  Space, Spin,
  Table, Typography,
} from 'antd';
import axios from 'axios';
import { Translate } from 'i18n-next-tools';
import { DateTime } from 'luxon';
import { useLocation } from 'react-router';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { API } from '../constsAPI';
import {
  DeviceReportType,
  FootprintDeviceType,
} from '../../../common/entityTypes';
import { Signal } from '../components/Signal';
import { DEFAULT_VALUE, PAGINATION_TABLE } from '../consts';
import { handlePageSize } from '../common';
import { Battery } from '../components/Battery';

const { Title } = Typography;

const getColumn: ColumnsType<FootprintDeviceType> = [
  {
    title: 'Customer',
    dataIndex: 'customer_name',
    key: 'customer_name',
  },
  {
    title: 'Location',
    dataIndex: 'location_name',
    key: 'location_name',
  },
  {
    title: 'Place',
    dataIndex: 'partner_footprint_id',
    key: 'partner_footprint_id',
    sorter: (a: any, b: any) => (a.customer_name > b.customer_name ? 1 : -1),
  },
  {
    title: 'Device Name',
    dataIndex: 'device_number',
    key: 'device_number',
  },
  {
    title: 'Last Report',
    dataIndex: 'latest_device_report',
    key: 'last_device_report_3',
    defaultSortOrder: 'descend',
    render: (value: DeviceReportType[]) => (value.length ? DateTime.fromISO(value[0].report_datetime).toUTC().toFormat('y-LL-dd tt') : DEFAULT_VALUE),
    sorter: (a: any, b: any) => {
      const time1 = a.latest_device_report[0].report_datetime ? DateTime.fromISO(a.latest_device_report[0].report_datetime).toMillis() : 0;
      const time2 = b.latest_device_report[0].report_datetime ? DateTime.fromISO(b.latest_device_report[0].report_datetime).toMillis() : 0;
      return (time1 > time2 ? 1 : -1);
    },
  },
  {
    title: 'Fill %',
    dataIndex: 'latest_device_report',
    key: 'last_device_report_4',
    sorter: (a: any, b: any) => (a.latest_device_report[0].fill_percent > b.latest_device_report[0].fill_percent ? 1 : -1),
    render: (value: DeviceReportType[]) => (value.length ? `${Math.round(value[0].fill_percent!)}%` : DEFAULT_VALUE),
  },
  {
    title: 'Signal',
    dataIndex: 'latest_device_report',
    key: 'last_device_report_5',
    render: (value: DeviceReportType[]) => (value.length ? <Signal rssi={value[0].signal_rsrp}/> : DEFAULT_VALUE),
  },
  {
    title: 'Battery',
    dataIndex: 'latest_device_report',
    key: 'latest_device_report_7',
    render: (value: DeviceReportType[]) => (value.length ? <Battery value={value[0].battery}/> : DEFAULT_VALUE),
  },
  {
    title: 'Ver',
    dataIndex: 'latest_device_report',
    key: 'latest_device_report_8',
    render: (value: DeviceReportType[]) => (value[0].appv || DEFAULT_VALUE),
  },
  {
    title: 'Service',
    dataIndex: 'latest_device_report',
    key: 'latest_device_report_6',
    width: 100,
    render: (value: DeviceReportType[]) => (value[0]?.service_button ? 'serviced' : ''),
  },
  {
    title: 'Add Service',
  },
  {
    title: 'Remove Service',
  },
  {
    title: 'Hide Report',
  },
  {
    title: 'Restore Report',
  },
];

export const FootprintDetailPage: React.FC = () => {
  const useQuery = useCallback(() => new URLSearchParams(useLocation().search), []);
  const [paginationTable, setPaginationTable] = useState<TablePaginationConfig>({
    ...PAGINATION_TABLE,
    pageSize: handlePageSize({ place: 'location_footprint_detail' }),
  });

  const query = useQuery();

  const columns: ColumnsType<FootprintDeviceType> = getColumn;

  const [spin, setSpin] = useState(false);
  const [dataTable, setDataTable] = useState<FootprintDeviceType[]>([]);

  const getDataDB = useCallback(async () => {
    const rowlimit = query.get('rowlimit') || '0';
    const footprint = query.get('footprint') || '';

    const dataDB = (await axios.get<FootprintDeviceType[]>(`${API.URL_AURORA_DB}/footprint_list?footprint=${footprint}&rowlimit=${rowlimit}`)).data;

    const place = dataDB[0];

    const newDataDB = place.latest_device_report ? place.latest_device_report.map((elem: DeviceReportType) => {
      const newPlace = { ...place };
      newPlace.latest_device_report = [];
      const newElem = elem;
      newElem.extra_data = elem.extra_data;
      newPlace.key = elem.device_report_id;
      newPlace.latest_device_report.push(newElem);

      return newPlace;
    }) : [];

    setDataTable(newDataDB);

    setSpin(false);
  }, []);

  useEffect(() => {
    setSpin(true);
    getDataDB();
  }, []);

  useEffect(() => {
  }, [paginationTable]);

  const handleTableChange = useCallback((pagination) => {
    handlePageSize({ place: 'location_footprint_detail', pageSize: pagination.pageSize });
    setPaginationTable(pagination);
  }, []);

  const t = Translate('DeviceListPage');
  // console.log('render Locations Details', dataTable);
  return (
    <>
      <Space direction={'horizontal'} align={'center'} style={{ justifyContent: 'space-between', width: '100%' }}>
        <Title level={3}>{t.k1('Footprint')}</Title>
      </Space>
      <Spin spinning={spin} tip={'please wait ...'}>
        <Table
          columns={columns}
          dataSource={dataTable}
          pagination={paginationTable}
          onChange={handleTableChange}
        />
      </Spin>
    </>
  );
};
