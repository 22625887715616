import {
  Button, Checkbox, Input, Modal, notification, Select, Space, Spin, Typography,
} from 'antd';
import { NotificationPlacement } from 'antd/es/notification';
import { DateTime, Duration } from 'luxon';
import { BarsOutlined, CheckCircleFilled } from '@ant-design/icons';
import {
  DirectionsRenderer, DirectionsService, GoogleMap, Marker, useJsApiLoader,
} from '@react-google-maps/api';
import React, {
  ReactNode, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { Translate } from 'i18n-next-tools';
import { AppDispatch, RootState } from '../../../store';
import {
  FloorplanType, FootprintType,
  LocationType,
  OperatorType,
  RouteLocationType,
  RouteScheduleFootprintType,
  RouteScheduleLocationType,
  RouteScheduleType,
  RouteType,
  WarehouseType,
} from '../../../../../common/entityTypes';
import { fetchLocationsById } from '../../../store/slices/locationSlice';
import { fetchWarehouseById } from '../../../store/slices/warehouseSlice';
import {
  createRouteSchedule,
  fetchRouteLocations,
  fetchRoutes,
  fetchRouteSchedule,
  fetchRouteScheduleFootprint,
  fetchScheduleLocationOrderByScheduleId,
  updateRouteScheduleFootprint,
  updateScheduleLocationOrder,
} from '../../../store/slices/routeSlice';
import { addSpinnerItem, removeSpinnerItem } from '../../../store/slices/spinnerSlice';
import { locationsQuery } from '../../../common';
import { AnswerVisitScheduleType } from '../../../../../common/types';
import { DragAndDrop } from '../components/dragAndDrop';
import { ScheduleModal } from '../components/scheduleModal';
import { fetchOperatorById } from '../../../store/slices/operatorSlice';
import { fetchDriverHistoryByScheduleId } from '../../../store/slices/driverSlice';
import { fetchFloorplan } from '../../../store/slices/floorplanSlice';
import { fetchFootprintListByLocationId } from '../../../store/slices/footprintSlice';

const { Title } = Typography;
const t = Translate('RouteSchedulePage');

const { Option } = Select;

enum TravelMode {
  DRIVING = 'DRIVING',
}

enum TrafficModel {
  BEST_GUESS = 'bestguess',
  OPTIMISTIC = 'optimistic',
  PESSIMISTIC = 'pessimistic',
}

const LOCATION_SERVICE_TIME = 5;
const BIN_SERVICE_TIME = 6;

const trafficOptions = [
  { label: 'Avoid Ferries', value: 'avoidFerries' },
  { label: 'Avoid Tolls', value: 'avoidTolls' },
  { label: 'Avoid Highways', value: 'avoidHighways' },
];

export const RouteSchedulePage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyBbpAbovI6ok3Ort4b897S8rbkykCUUQxE', // ,
  });
  const successIcon = <CheckCircleFilled style={{ color: 'green' }}/>;
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement: NotificationPlacement, message: string, desc: string, icon: ReactNode) => {
    api.info({
      message,
      description: desc,
      placement,
      icon,
    });
  };

  const [isSettingsModalVisible, setIsSettingsModalVisible] = useState(false);
  const [isRouteScheduleModalVisible, setIsRouteScheduleModalVisible] = useState(false);
  const [trafficModel, setTrafficModel] = useState<any>(TrafficModel.PESSIMISTIC);
  const [optimizeWaypoints, setOptimizeWaypoints] = useState<boolean>(true);
  const [avoidFerries, setAvoidFerries] = useState<boolean>(true);
  const [avoidTolls, setAvoidTolls] = useState<boolean>(true);
  const [avoidHighways, setAvoidHighways] = useState<boolean>(true);
  const [checkedTrafficOptions, setCheckedTrafficOptions] = useState<any>([trafficOptions[0].value, trafficOptions[1].value, trafficOptions[2].value]);
  const [timeToWarehouse, setTimeToWarehouse] = useState<any>(null);
  const [response, setResponse] = useState<any>(null);
  const [startHours, setStartHours] = useState<number>(9);
  const [origin, setOrigin] = useState<any>(null);
  const [destination, setDestionation] = useState<any>(null);
  const [waypoints, setWaypoints] = useState<any>(null);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [isFootorintChanged, setIsFootorintChanged] = useState<boolean>(false);
  const [selectedRoute, setSelectedRoute] = useState<number | undefined>(undefined);
  const [scheduleDateTime, setScheduleDateTime] = useState<string | undefined>(undefined);
  const [scheduleRouteSchedule, setScheduleRouteSchedule] = useState<number | undefined>(undefined);
  const [routeScheduleTime, setRouteScheduleTime] = useState<number | undefined>(undefined);
  const [newLocationsOrder, setNewLocationsOrder] = useState<any>([]);
  const [locationsIdsWithData, setLocationsIdsWithData] = useState<{
    [key: string]: LocationType
  } | undefined>(undefined);
  const [selectedFootprints, setSelectedFootprints] = useState<any>([]);
  const [driverWorkHours, setDriverWorkHours] = useState<any>(8);
  const locationList = useSelector<RootState, LocationType[]>((store) => store.location.locationList);
  const warehouse = useSelector<RootState, WarehouseType[]>((store) => store.warehouse.warehouse);
  const routeList = useSelector<RootState, RouteType[]>((store) => store.route.routeList);
  const footpintList = useSelector<RootState, FootprintType[]>((store) => store.footprint.footprintList);
  const floorplanList = useSelector<RootState, FloorplanType[]>((store) => store.floorplan.floorplanList);
  const [preparedFloorplanList, setPreparedFloorplanList] = useState<FloorplanType[]>([]);
  const routeScheduleList = useSelector<RootState, RouteScheduleType[]>((store) => store.route.routeScheduleList);
  const routeScheduleFootprintList = useSelector<RootState, RouteScheduleFootprintType[]>((store) => store.route.routeScheduleFootprintList);
  const routeLocationList = useSelector<RootState, RouteLocationType[]>((store) => store.route.routeLocationList);
  const routeScheduleLocationList = useSelector<RootState, RouteScheduleLocationType[]>((store) => store.route.routeScheduleLocationList);
  const operator = useSelector<RootState, OperatorType[]>((store) => store.operator.operator);
  const spinner = useSelector<RootState, boolean>((store) => store.spinner.spinner);

  useEffect(() => {
    (async () => {
      dispatch(addSpinnerItem('route_schedule'));
      if (!routeList.length) await dispatch(fetchRoutes());
      dispatch(removeSpinnerItem('route_schedule'));
    })();
  }, []);

  useEffect(() => {
    if (selectedRoute) {
      (async () => {
        dispatch(addSpinnerItem('route_schedule'));
        await dispatch(fetchRouteSchedule({ route_id: selectedRoute }));
        await dispatch(fetchRouteLocations({ route_id: selectedRoute }));
        await dispatch(fetchWarehouseById(`warehouse_id=${routeList.find((route) => route.route_id === selectedRoute)?.warehouse_id}`));
        await dispatch(fetchOperatorById({ operator_id: routeList.find((route) => route.route_id === selectedRoute)?.operator_id! }));
        dispatch(removeSpinnerItem('route_schedule'));
      })();
    }
  }, [selectedRoute]);

  useEffect(() => {
    if (floorplanList && footpintList) {
      const newFloorplanList = floorplanList.map((floorplan) => ({
        ...floorplan,
        footprintData: footpintList.filter((footprint) => footprint.floorplan_id === floorplan.floorplan_id),
      }));
      setPreparedFloorplanList(newFloorplanList);
    }
  }, [footpintList, floorplanList]);

  useEffect(() => {
    if (routeLocationList.length) {
      (async () => {
        dispatch(addSpinnerItem('route_schedule'));
        await dispatch(fetchLocationsById(locationsQuery(routeLocationList).join('&')));
        await dispatch(fetchFloorplan(locationsQuery(routeLocationList).join('&')));
        await dispatch(fetchFootprintListByLocationId(locationsQuery(routeLocationList).join('&')));
        dispatch(removeSpinnerItem('route_schedule'));
      })();
    }
  }, [routeLocationList]);

  useEffect(() => {
    if (scheduleRouteSchedule) {
      (async () => {
        dispatch(addSpinnerItem('route_schedule'));
        await dispatch(fetchRouteScheduleFootprint({ route_schedule_id: scheduleRouteSchedule }));
        await dispatch(fetchScheduleLocationOrderByScheduleId({ route_schedule_id: scheduleRouteSchedule }));
        await dispatch(fetchDriverHistoryByScheduleId(scheduleRouteSchedule));
        dispatch(removeSpinnerItem('route_schedule'));
      })();
    }
  }, [scheduleRouteSchedule]);

  const getRoute = useCallback(async (props) => {
    console.log('getRoute');
    dispatch(addSpinnerItem('route_schedule'));
    const directionsService = new google.maps.DirectionsService();
    console.log('operator', operator);
    const schedule = routeScheduleList.find((item) => item.route_schedule_id === scheduleRouteSchedule);
    console.log('schedule', schedule);
    let newDepartureTime = DateTime.fromISO(`${schedule?.schedule_datetime}T${schedule?.start_hours}`, { zone: 'America/New_York' });
    console.log('newDepartureTime.toJSDate()', newDepartureTime.toJSDate());
    const requestRoute = {
      origin: props.origin,
      destination: props.destination,
      travelMode: TravelMode.DRIVING,
      avoidFerries,
      avoidHighways,
      avoidTolls,
      waypoints: props.waypoints,
      drivingOptions: {
        departureTime: newDepartureTime.toJSDate(),
        trafficModel,
      },
    };
    // @ts-ignore
    if (optimizeWaypoints) requestRoute.optimizeWaypoints = true;

    const resultRoute: any = await new Promise((resolve) => {
      directionsService.route(requestRoute, (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          resolve(result);
        }
      });
    });

    console.log('resultRoute', resultRoute);

    const locationsWithTraffic: any = [];
    let timeAccum = 0;
    let toWarehouse = 0;

    if (resultRoute.routes[0].legs) {
      let delayFactor = 0;
      const getDirectionsRoute = async (request: any) => {
        let trafficResponse = null;
        try {
          await directionsService.route(request, (result, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
              trafficResponse = result;
            }
          });
        } catch (e) {
          delayFactor++;
          await new Promise((resolve) => setTimeout(resolve, delayFactor * 1000));
          await directionsService.route(request, (result, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
              trafficResponse = result;
            }
          });
        }

        return trafficResponse;
      };

      for await (const [index, leg] of resultRoute.routes[0].legs.entries()) {
        console.log(index);
        // @ts-ignore
        const endDirectionLocation: any = Object.values(props.locationsIds).find((location) => leg.end_address.includes(location.address1));
        const endDirectionWarehouse = warehouse.find((war) => leg.end_address.includes(war.address));
        const requestLeg = {
          origin: leg.start_address,
          destination: leg.end_address,
          travelMode: TravelMode.DRIVING,
          avoidFerries,
          avoidHighways,
          avoidTolls,
          drivingOptions: {
            departureTime: newDepartureTime.toJSDate(),
            trafficModel,
          },
        };
        // @ts-ignore
        if (endDirectionLocation?.do_service_footpirnt_count > 0) {
          console.log('endDirectionLocation', endDirectionLocation);
          let routeWithTraffic: any = await getDirectionsRoute(requestLeg);
          const traffic = routeWithTraffic?.routes[0]?.legs[0]?.duration_in_traffic?.value / 60 || routeWithTraffic?.routes[0]?.legs[0]?.duration?.value / 60 || 0;
          // @ts-ignore
          const locationTime = (LOCATION_SERVICE_TIME + endDirectionLocation?.do_service_footpirnt_count * BIN_SERVICE_TIME);
          newDepartureTime = newDepartureTime.plus({ minutes: traffic + locationTime });
          timeAccum += traffic + locationTime;
          routeWithTraffic = {
            ...routeWithTraffic,
            location_id: endDirectionLocation.location_id,
            location_time: locationTime,
            traffic_time: traffic,
            order: index + 1,
          };
          console.log('routeWithTraffic', routeWithTraffic);
          locationsWithTraffic.push(routeWithTraffic);
        }

        if (endDirectionWarehouse) {
          console.log('endDirectionWarehouse', endDirectionWarehouse);
          const routeWithTraffic: any = await getDirectionsRoute(requestLeg);
          const traffic = routeWithTraffic?.routes[0]?.legs[0]?.duration_in_traffic?.value / 60 || routeWithTraffic?.routes[0]?.legs[0]?.duration?.value / 60 || 0;
          // @ts-ignore
          timeAccum += traffic;
          toWarehouse = traffic;
          console.log('routeWithTraffic', routeWithTraffic);
        }
      }
    }
    dispatch(removeSpinnerItem('route_schedule'));
    return {
      resultRoute,
      locationsWithTraffic,
      timeAccum,
      toWarehouse,
    };
  }, [
    trafficModel,
    avoidFerries,
    avoidHighways,
    avoidTolls,
    warehouse,
    optimizeWaypoints,
    operator,
    driverWorkHours,
    startHours,
  ]);

  useEffect(() => {
    (async () => {
      if (routeScheduleFootprintList.length && routeScheduleLocationList.length && locationList.length && warehouse.length && operator.length) {
        const locationsIds: { [key: string]: LocationType } = {};

        locationList.forEach((location) => {
          // @ts-ignore
          locationsIds[`${location.location_id}`] = { ...location, footprints: [] };
        });

        routeScheduleFootprintList.forEach((routeScheduleFootprint) => {
          console.log('routeScheduleFootprint', routeScheduleFootprint);
          console.log('(routeScheduleFootprint.raw_data_visit as AnswerVisitScheduleType).doService', (routeScheduleFootprint.raw_data_visit as AnswerVisitScheduleType).doService);
          locationsIds[routeScheduleFootprint.location_id] = {
            ...locationsIds[routeScheduleFootprint.location_id],
            // @ts-ignore
            do_service: locationsIds[routeScheduleFootprint.location_id].do_service_footpirnt_count > 0,
            // @ts-ignore
            do_service_footpirnt_count: (routeScheduleFootprint.raw_data_visit as AnswerVisitScheduleType).doService ? (locationsIds[routeScheduleFootprint.location_id].do_service_footpirnt_count || 0) + 1 : locationsIds[routeScheduleFootprint.location_id].do_service_footpirnt_count || 0,
            // @ts-ignore
            footpirnt_count: (locationsIds[routeScheduleFootprint.location_id].footpirnt_count || 0) + 1,
            // @ts-ignore
            footprints: [...locationsIds[routeScheduleFootprint.location_id].footprints, routeScheduleFootprint],
          };
        });

        console.log('locationsIds --->>>>> ', locationsIds);

        let wayp: any = [];
        const destinationRoute = { lat: Number(warehouse[0].latitude), lng: Number(warehouse[0].longitude) };
        const originRoute = { lat: Number(warehouse[0].latitude), lng: Number(warehouse[0].longitude) };

        if (Object.values(locationsIds).length) {
          Object.values(locationsIds).forEach((location) => {
            const routeScheduleLocation = routeScheduleLocationList.find((routeScheduleLocationItem) => routeScheduleLocationItem.location_id === location.location_id);
            let underLine;
            let order;
            console.log('routeScheduleLocation', routeScheduleLocation);
            if (routeScheduleLocation) {
              // @ts-ignore
              underLine = locationsIds[location.location_id!].do_service_footpirnt_count === 0 || routeScheduleLocation.route_order === 0;
              // @ts-ignore
              order = locationsIds[location.location_id!].do_service_footpirnt_count === 0 ? 0 : routeScheduleLocation.route_order;
            } else {
              order = null;
              // @ts-ignore
              underLine = locationsIds[location.location_id].do_service_footpirnt_count === 0;
            }

            if (location.location_id) {
              locationsIds[location.location_id] = {
                ...locationsIds[location.location_id],
                // @ts-ignore
                underLine,
                order,
              };
            }
          });

          wayp = Object.values(locationsIds).map((location) => {
            // @ts-ignore
            if (!location.underLine && location.do_service) {
              return {
                location: { lat: Number(location.latitude), lng: Number(location.longitude) },
                stopover: true,
              };
            }

            return null;
          }).filter((item: any) => item);
        }

        const responseRoutes = await getRoute({
          origin: originRoute,
          destination: destinationRoute,
          waypoints: wayp,
          locationsIds,
        });

        const {
          resultRoute,
          locationsWithTraffic,
          timeAccum,
          toWarehouse,
        } = responseRoutes;

        Object.values(locationsIds).forEach((location) => {
          const locationWithTraffic = locationsWithTraffic.find((locWithTraffic: any) => locWithTraffic.location_id === location.location_id);
          if (locationWithTraffic) {
            locationsIds[locationWithTraffic.location_id] = {
              ...locationsIds[locationWithTraffic.location_id],
              // @ts-ignore
              location_time: locationWithTraffic.location_time,
              traffic_time: locationWithTraffic.traffic_time,
              // @ts-ignore
              order: locationsIds[locationWithTraffic.location_id].order !== null ? locationsIds[locationWithTraffic.location_id].order : locationWithTraffic.order,
            };
          }
        });

        console.log(locationsIds);
        setOrigin(originRoute);
        setDestionation(destinationRoute);
        setWaypoints(wayp);
        setResponse(resultRoute);
        setRouteScheduleTime(timeAccum);
        setLocationsIdsWithData(locationsIds);
        setTimeToWarehouse(toWarehouse);
      }
    })();
  }, [routeScheduleFootprintList, routeScheduleLocationList, locationList, warehouse]);

  useEffect(() => {
    (async () => {
      if (locationsIdsWithData && Object.values(locationsIdsWithData).length && isChanged) {
        const locationListWIthOrder = newLocationsOrder.length ? newLocationsOrder : Object.values(locationsIdsWithData);

        let wayp: any = [];
        const destinationRoute = { lat: Number(warehouse[0].latitude), lng: Number(warehouse[0].longitude) };
        const originRoute = { lat: Number(warehouse[0].latitude), lng: Number(warehouse[0].longitude) };
        // @ts-ignore
        wayp = locationListWIthOrder.map((location) => {
          if (!location.underLine && location.do_service && !location.type) {
            return {
              location: { lat: Number(location.latitude), lng: Number(location.longitude) },
              stopover: true,
            };
          }

          return null;
        }).filter((item: any) => item);

        const responseRoutes = await getRoute({
          origin: { lat: Number(warehouse[0].latitude), lng: Number(warehouse[0].longitude) },
          destination: { lat: Number(warehouse[0].latitude), lng: Number(warehouse[0].longitude) },
          waypoints: wayp,
          locationsIds: locationsIdsWithData,
        });

        const {
          resultRoute,
          locationsWithTraffic,
          timeAccum,
          toWarehouse,
        } = responseRoutes;

        Object.values(locationsIdsWithData).forEach((location: any) => {
          const locationWithTraffic = locationsWithTraffic.find((locWithTraffic: any) => locWithTraffic.location_id === location.location_id);
          const locationWIthOrder = locationListWIthOrder.find((locWithOrder: any) => locWithOrder.location_id === location.location_id);

          if (locationWithTraffic) {
            locationsIdsWithData[locationWithTraffic.location_id] = {
              ...locationsIdsWithData[locationWithTraffic.location_id],
              // @ts-ignore
              location_time: locationWithTraffic.location_time,
              traffic_time: locationWithTraffic.traffic_time,
              order: locationWithTraffic.order,
              underLine: locationWIthOrder.underLine,
            };
          } else {
            locationsIdsWithData[location.location_id] = {
              ...locationsIdsWithData[location.location_id],
              // @ts-ignore
              location_time: 0,
              traffic_time: 0,
              order: 0,
              underLine: locationWIthOrder.underLine,
            };
          }
        });

        setOrigin(originRoute);
        setDestionation(destinationRoute);
        setWaypoints(wayp);
        setResponse(resultRoute);
        setLocationsIdsWithData(locationsIdsWithData);
        setRouteScheduleTime(timeAccum);
        setTimeToWarehouse(toWarehouse);
        setIsChanged(false);
      }
    })();
  }, [locationsIdsWithData, newLocationsOrder, optimizeWaypoints, isChanged]);

  useEffect(() => {
    if (locationsIdsWithData && Object.values(locationsIdsWithData).length && newLocationsOrder.length && isFootorintChanged) {
      Object.values(locationsIdsWithData).forEach((location: any) => {
        const locationWIthOrder = newLocationsOrder.find((locWithOrder: any) => locWithOrder.location_id === location.location_id);
        if (locationWIthOrder) {
          locationsIdsWithData[locationWIthOrder.location_id] = {
            ...locationsIdsWithData[locationWIthOrder.location_id],
            // @ts-ignore
            footprints: locationWIthOrder.footprints,
          };
        }
      });

      setLocationsIdsWithData(locationsIdsWithData);
      setIsFootorintChanged(false);
    }
  }, [newLocationsOrder, isFootorintChanged]);

  if (loadError) {
    console.log('loadError', loadError);
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  const directionsCallback = useCallback(async (propResponse: any) => {
    console.log('propResponse', propResponse);
  }, []);

  const onMapClick = (...args: any) => {
    console.log('onClick args: ', args);
  };

  const handleRoute = (value: number) => {
    setSelectedRoute(value);
  };

  const showRouteScheduleModal = () => {
    setIsRouteScheduleModalVisible(true);
  };

  const showSettingsModal = () => {
    setIsSettingsModalVisible(true);
  };

  const handleCancelSettingsModal = () => {
    setIsSettingsModalVisible(false);
  };

  const handleCancelRouteScheduleModal = () => {
    setIsRouteScheduleModalVisible(false);
  };

  const handleScheduleDateTime = (_: any, dateTime: string) => {
    setScheduleDateTime(dateTime);
  };

  const handleEditSchedule = async () => {
    if (locationsIdsWithData && Object.values(locationsIdsWithData).length) {
      console.log('locationsIdsWithData', locationsIdsWithData);
      const locations = Object.values(locationsIdsWithData).map((location: any) => ({
        order: location.order,
        location_id: location.location_id,
        estimated_location_service_time: Math.round(location.location_time) || 0,
        estimated_location_traffic_time: Math.round(location.traffic_time) || 0,
      }));

      dispatch(addSpinnerItem('route_schedule'));
      console.log('locations', locations);
      await dispatch(updateScheduleLocationOrder({
        avoidFerries,
        avoidTolls,
        avoidHighways,
        optimizeWaypoints,
        driverWorkHours,
        startHours,
        trafficModel,
        locations,
        estimated_schedule_service_time: routeScheduleTime ? Math.round(routeScheduleTime) : 0,
        route_schedule_id: scheduleRouteSchedule,
      }));
      openNotification('topRight', 'Location order saved', '', successIcon);
      dispatch(removeSpinnerItem('route_schedule'));
    }
  };

  const handleCreateRouteSchedule = async () => {
    dispatch(addSpinnerItem('route_schedule'));
    const routeName = routeList?.find((route) => route.route_id === selectedRoute)?.route_name;
    if (routeName) {
      await dispatch(createRouteSchedule({
        route_name: routeName,
        schedule_datetime: scheduleDateTime,
      }));
      if (selectedRoute) await dispatch(fetchRouteSchedule({ route_id: selectedRoute }));
      await handleEditSchedule();
    }
    handleCancelRouteScheduleModal();
    openNotification('topRight', 'Schedule created', '', successIcon);
    dispatch(removeSpinnerItem('route_schedule'));
  };

  const handleSchedule = (value: number) => {
    console.log('value', value);
    setScheduleRouteSchedule(value);
    const schedule = routeScheduleList.find((scheduleItem) => scheduleItem.route_schedule_id === value);

    if (schedule) {
      setStartHours(schedule.start_hours);
      setDriverWorkHours(schedule.driver_work_hours);
      setAvoidHighways(schedule.avoid_highways);
      setAvoidTolls(schedule.avoid_tolls);
      setAvoidFerries(schedule.avoid_ferries);
      setOptimizeWaypoints(schedule.optimize_waypoints);
      setTrafficModel(schedule.traffic_model);
      setCheckedTrafficOptions([schedule.avoid_highways ? trafficOptions[2].value : [], schedule.avoid_ferries ? trafficOptions[0].value : [], schedule.avoid_tolls ? trafficOptions[1].value : []]);
    }
  };

  const handleChangeTrafficOptions = (value: CheckboxValueType[]) => {
    setAvoidFerries(value.includes(trafficOptions[0].value));
    setAvoidTolls(value.includes(trafficOptions[1].value));
    setAvoidHighways(value.includes(trafficOptions[2].value));
    setCheckedTrafficOptions(value);
    setIsChanged(true);
  };

  const handleTrafficModel = (value: any) => {
    if (value === TrafficModel.BEST_GUESS) setTrafficModel(TrafficModel.BEST_GUESS);
    if (value === TrafficModel.OPTIMISTIC) setTrafficModel(TrafficModel.OPTIMISTIC);
    if (value === TrafficModel.PESSIMISTIC) setTrafficModel(TrafficModel.PESSIMISTIC);
    setIsChanged(true);
  };

  const onChangeOptimizeWaypoints = (event: any) => {
    setOptimizeWaypoints(event.target.checked);
    setIsChanged(true);
  };

  const handleClickSaveDoServiceChanges = () => {
    if (selectedFootprints.length) {
      (async () => {
        dispatch(addSpinnerItem('route_schedule'));
        await dispatch(updateRouteScheduleFootprint(selectedFootprints));
        if (scheduleRouteSchedule) await dispatch(fetchRouteScheduleFootprint({ route_schedule_id: scheduleRouteSchedule }));
        setSelectedFootprints([]);
        openNotification('topRight', 'Devices to service changed', '', successIcon);
        dispatch(removeSpinnerItem('route_schedule'));
        setIsChanged(true);
      })();
    }
  };

  const handleChangeDriverWorkHours = (event: any) => {
    if (event.target.value) {
      setDriverWorkHours(event.target.value);
      setIsChanged(true);
    }
  };

  const handleChangeStartAt = (event: any) => {
    if (Number(event.target.value)) {
      setStartHours(event.target.value);
      setIsChanged(true);
    }
  };

  const map = useMemo(() => {
    console.log('response', response);
    return (
      <GoogleMap
        id="direction-example"
        mapContainerStyle={{
          height: '65vh',
          width: '100%',
        }}
        zoom={Number(warehouse[0]?.latitude) ? 9 : 2}
        center={{
          lat: Number(warehouse[0]?.latitude) || 0,
          lng: Number(warehouse[0]?.longitude) || 0,
        }}
        onClick={onMapClick}
      >
        {
          warehouse && warehouse.length && warehouse.map((war) => (
            <Marker
              key={war.warehouse_id}
              icon={{ url: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png' }}
              position={{ lat: Number(war.latitude), lng: Number(war.longitude) }}
            />
          ))
        }
        {
          (destination && origin && waypoints && waypoints.length) && (
            <DirectionsService
              options={{
                destination,
                origin,
                optimizeWaypoints: true,
                travelMode: TravelMode.DRIVING,
                waypoints,
              }}
              callback={directionsCallback}
            />
          )
        }
        {
          response && (
            <DirectionsRenderer
              options={{
                directions: response,
              }}
              onLoad={(directionsRenderer) => {
                console.log('DirectionsRenderer onLoad directionsRenderer: ', directionsRenderer);
              }}
              onUnmount={(directionsRenderer) => {
                console.log('DirectionsRenderer onUnmount directionsRenderer: ', directionsRenderer);
              }}
            />
          )
        }
      </GoogleMap>
    );
  }, [destination, origin, waypoints, response, warehouse]);

  console.log(locationsIdsWithData);

  return (
    <>
      <Space direction={'horizontal'} align={'center'} style={{ justifyContent: 'space-between', width: '100%' }}>
        <Title level={3}>{t.k1('Route Schedule')}</Title>
      </Space>
      {contextHolder}
      <ScheduleModal
        spinner={spinner}
        isRouteScheduleModalVisible={isRouteScheduleModalVisible}
        handleCancelRouteScheduleModal={handleCancelRouteScheduleModal}
        handleCreateRouteSchedule={handleCreateRouteSchedule}
        handleScheduleDateTime={handleScheduleDateTime}
      />
      <Spin spinning={spinner} tip={'please wait ...'}>
        <div className="map">
          {isLoaded ? <>
            <div className="map__info-box">
              <div className="map__route-manage">
                <Select
                  onChange={handleRoute}
                  allowClear
                  placeholder={'Select route'}
                  value={selectedRoute}
                >
                  {routeList.map((route) => (
                    <Option
                      key={route.route_id}
                      value={route.route_id!}
                    >
                      {route.route_name}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="map__route-manage">
                {selectedRoute && <Button onClick={showRouteScheduleModal}>Create schedule</Button>}
                {selectedRoute
                  && <Select
                    onChange={handleSchedule}
                    allowClear
                    placeholder={'Select schedule'}
                  >
                    {routeScheduleList.map((routeSchedule) => (
                      <Option
                        key={routeSchedule.route_schedule_id}
                        value={routeSchedule.route_schedule_id!}
                      >
                        {routeSchedule.schedule_datetime}
                      </Option>
                    ))}
                  </Select>}
              </div>

              {locationsIdsWithData && Object.values(locationsIdsWithData).length && (
                <div className="map__route-info">
                  <Modal
                    title="Settings"
                    visible={isSettingsModalVisible}
                    onCancel={handleCancelSettingsModal}
                    footer={[
                      <Button key="cancel" onClick={handleCancelSettingsModal}>
                        Cancel
                      </Button>,
                    ]}
                  >
                    <div className="settings__modal">
                      <Input
                        type={'number'}
                        addonBefore="Driver work hours"
                        onChange={handleChangeDriverWorkHours}
                        value={driverWorkHours}
                      />
                      <Input
                        type={'number'}
                        addonBefore="Start at (0 - 24)"
                        onChange={handleChangeStartAt}
                        value={startHours}
                      />
                      <Checkbox.Group
                        options={trafficOptions}
                        value={checkedTrafficOptions}
                        onChange={handleChangeTrafficOptions}
                      />
                      <Select
                        onChange={handleTrafficModel}
                        allowClear
                        placeholder={'Select traffic model'}
                        value={trafficModel}
                      >
                        <Option
                          value={TrafficModel.BEST_GUESS}
                        >
                          {TrafficModel.BEST_GUESS.toLocaleUpperCase()}
                        </Option>
                        <Option
                          value={TrafficModel.OPTIMISTIC}
                        >
                          {TrafficModel.OPTIMISTIC.toLocaleUpperCase()}
                        </Option>
                        <Option
                          value={TrafficModel.PESSIMISTIC}
                        >
                          {TrafficModel.PESSIMISTIC.toLocaleUpperCase()}
                        </Option>
                      </Select>
                      <Checkbox
                        checked={optimizeWaypoints}
                        onChange={onChangeOptimizeWaypoints}
                      >
                        Optimize Waypoints
                      </Checkbox>
                    </div>
                  </Modal>
                  <div className="map__route-info-btn">
                    <Button
                      onClick={showSettingsModal}
                    >
                      <BarsOutlined/>
                    </Button>
                    <Button
                      disabled={!newLocationsOrder.length}
                      onClick={handleClickSaveDoServiceChanges}
                    >
                      Save changes
                    </Button>
                    <Button onClick={handleEditSchedule}>Save location order</Button>
                  </div>
                </div>
              )}
              {locationsIdsWithData
                && <DragAndDrop
                  locationData={Object.values(locationsIdsWithData).map((location: any) => ({
                    ...location,
                    floorplans: preparedFloorplanList.filter((florplan: any) => florplan.location_id === location.location_id),
                  }))
                  }
                  preparedFloorplanList={preparedFloorplanList}
                  setIsDrugged={setIsChanged}
                  setNewLocationsOrder={setNewLocationsOrder}
                  setSelectedFootprints={setSelectedFootprints}
                  response={response}
                  timeToWarehouse={timeToWarehouse}
                  driverWorkHours={driverWorkHours}
                  setIsFootorintChanged={setIsFootorintChanged}
                  isFootorintChanged={isFootorintChanged}
                />
              }
            </div>
            <div className="map__map-box">
              {map}
              <div className="map__map-box-info">
                <div>
                  <p>Route schedule time {Duration.fromObject({ minutes: routeScheduleTime }).toFormat('hh:mm')}</p>
                  <p>Time back to warehouse {Duration.fromObject({ minutes: timeToWarehouse }).toFormat('hh:mm')}</p>
                </div>
                <div>
                  {scheduleRouteSchedule
                    && <p>Start at {
                      DateTime.fromJSDate(new Date(`${routeScheduleList.find((item) => item.route_schedule_id === scheduleRouteSchedule)?.schedule_datetime} ${startHours}:00:00`))
                        .toFormat('y-LL-dd hh:mm:ss a')
                        .toString()
                    }
                    </p>
                  }
                  {scheduleRouteSchedule
                    && <p>End at {
                      DateTime.fromJSDate(new Date(`${routeScheduleList.find((item) => item.route_schedule_id === scheduleRouteSchedule)?.schedule_datetime} ${startHours}:00:00`))
                        .plus({ minutes: routeScheduleTime })
                        .toFormat('y-LL-dd hh:mm:ss a')
                        .toString()
                    }
                    </p>
                  }
                </div>
              </div>
            </div>
          </> : <>Please wait</>
          }
        </div>
      </Spin>
    </>
  );
};
