import * as React from 'react';
import { HeaderPage } from './HeaderPage';
import { SpaceWrapperStyle } from '../style_component/SpaceWrapper';

type PageTemplateProps = {
  onClickCallback?: any,
};

export const PageTemplate: React.FC<PageTemplateProps> = ({ onClickCallback, children }) => (
  <SpaceWrapperStyle direction="vertical" onClick={onClickCallback} padding="10px">
    <HeaderPage />
    {children}
  </SpaceWrapperStyle>
);
