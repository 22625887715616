import { Duration } from 'luxon';
import React from 'react';
import { Collapse, Checkbox, Alert } from 'antd';
import { FloorplanType, FootprintType } from '../../../../../common/entityTypes';

const { Panel } = Collapse;

export const LocationItem = (props: any) => {
  const {
    location,
    setSelectedFootprints,
    handleChangeFootprintDoService,
    setCurrentFloorplan,
  } = props;

  const onChange = (key: string | string[]) => {
    console.log(key);
  };
  const onChangeFootprintDoService = (event: any, footprint: any) => {
    const formatedFootprint: any = {
      ...footprint,
      ...footprint.raw_data_visit,
      doService: event.target.checked,
    };

    delete formatedFootprint.raw_data_visit;

    setSelectedFootprints((prevState: any) => [...prevState, formatedFootprint]);
    handleChangeFootprintDoService(footprint, event.target.checked);
  };

  console.log('location.footprints', location.footprints);
  // console.log('location.floorplans', location.floorplans);

  return (
    location.type === 'line'
      ? (
        <div className="draggable__line">
          <p>{location.location_name}</p>
          <p>{location.desc}</p>
        </div>
      ) : (
        <div className={location.underLine ? 'draggable__underline' : ''}>
          <Collapse onChange={onChange}>
            <Panel header={
              <>
                {location.driverOutOfWork && <Alert message={<>Serving this place outside driver hours
                  for {Duration.fromObject({ minutes: location.driverOutOfTime }).toFormat('hh:mm')}</>} type="warning"/>}
                {location.location_name} - {location.address1} {location.state_province} {location.zip} {location.city}
                <div className="location__info">
                  <p>{location.do_service_footpirnt_count} / {location.footpirnt_count}</p>
                  <p>location service
                    time {Duration.fromObject({ minutes: location.location_time }).toFormat('hh:mm')}</p>
                  <p>location traffic
                    time {Duration.fromObject({ minutes: location.traffic_time }).toFormat('hh:mm')}</p>
                </div>
              </>
            } key={location.location_id}>
              {
                location.floorplans.map((floorplan: FloorplanType & { footprintData: FootprintType[] }) => <div
                  key={floorplan.floorplan_id} className="location__info-footprint">
                  <div
                    className="location__info-floorplan"
                    onClick={() => {
                      setCurrentFloorplan(floorplan);
                    }}
                  >{`${floorplan.name}`}</div>
                  {location.footprints
                    .filter((footprint: FootprintType) => floorplan.footprintData.find((subFootprint) => subFootprint.footprint_id === footprint.footprint_id!))
                    .map((footprint: any) => (
                      <div key={footprint.footprint_id}>
                        <Checkbox
                          checked={footprint.raw_data_visit.doService}
                          onChange={(event) => onChangeFootprintDoService(event, footprint)}
                        >
                          {footprint.raw_data_visit.visitLineId}
                        </Checkbox>
                      </div>
                    ))}
                </div>)
              }
            </Panel>
          </Collapse>
        </div>
      )
  );
};
