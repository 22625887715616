import { RecurrenceType } from '../../../common/types';

export const INCHES = 2.54;

export const recurrence: RecurrenceType[] = ['1 week', '2 week', '3 week', '4 week'];

export const DEFAULT_FILL_TRIGGER_PERCENT = 80;
export const DEFAULT_MAX_DAY_WITHOUT_VISIT = 0;
export const DEFAULT_DISTANCE_CONTAINER_IN_GROUP = 6;
export const DEFAULT_DAYS_BETWEEN_SERVICE = 0;
export const DEFAULT_FLEXIBILITY = 0;
export const DEFAULT_TRIGGER_SERVICE = false;
export const DEFAULT_DAY_WEEK = {
  fri: false, mon: false, sat: false, sun: false, thu: false, tue: false, wed: false,
};
export const DEFAULT_RECURRENCE = recurrence[0];
export const daysWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
