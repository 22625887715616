import {
  Button, Space, Table, Typography,
} from 'antd';
import type { TableProps } from 'antd/es/table';
import { useDispatch, useSelector } from 'react-redux';
import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Translate } from 'i18n-next-tools';
import { columns } from '../config';
import './CustomerPage.scss';
import { fetchCustomersByRegions } from '../../../store/slices/customerSlice';
import { AppDispatch, RootState } from '../../../store';
import { CustomerType, RegionType } from '../../../../../common/entityTypes';
import { fetchRegions } from '../../../store/slices/regionSlice';
import { customersQuery } from '../../../common';

const { Title } = Typography;
const t = Translate('CustomersPage');

export const CustomersPage: FC = () => {
  const tableProps: TableProps<CustomerType> = {
    size: 'large',
  };

  const dispatch = useDispatch<AppDispatch>();
  const customerList = useSelector<RootState, CustomerType[]>((store) => store.customer.customerList);
  const regionList = useSelector<RootState, RegionType[]>((store) => store.region.regionList);
  const customerLoader = useSelector<RootState, boolean>((store) => store.customer.customerLoader);

  useEffect(() => {
    if (!customerList.length) {
      dispatch(fetchRegions());
    }
  }, []);

  useEffect(() => {
    if (!customerList.length && regionList.length) {
      dispatch(fetchCustomersByRegions(customersQuery(regionList).join('&')));
    }
  }, [regionList]);

  const history = useHistory();

  return (
    <>
      <Space direction={'horizontal'} align={'center'} style={{ justifyContent: 'space-between', width: '100%' }}>
        <Title level={3}>{t.k1('Customers')}</Title>
      </Space>
      <div className="customers__actions">
        <Button href={'/customer/add'}>Add Customer</Button>
      </div>
      <Table
        {...tableProps}
        columns={columns}
        className={'click_row'}
        dataSource={customerList}
        loading={customerLoader}
        onRow={(record) => ({
          onClick: () => {
            const params = [
              `customer=${record.customer_id}`,
              'rowlimit=100',
            ];
            const link = `/customer/detail?${params.join('&')}`;
            history.push(link);
          },
        })}
      />
    </>
  );
};
