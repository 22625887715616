import React, { useCallback, useEffect, useState } from 'react';
import {
  Space, Spin,
  Table, Typography,
} from 'antd';
import axios from 'axios';
import { Translate } from 'i18n-next-tools';
import { DateTime } from 'luxon';
import { useLocation } from 'react-router';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { API } from '../constsAPI';
import {
  CustomerType,
  DeviceReportType,
  DeviceType,
  FootprintType, LocationType,
} from '../../../common/entityTypes';
import { Signal } from '../components/Signal';
import { DEFAULT_VALUE, PAGINATION_TABLE } from '../consts';
import { INCHES } from '../common/web_consts';
import { Battery } from '../components/Battery';
import { handlePageSize } from '../common';

const { Title } = Typography;

export const DeviceDetailPage: React.FC = () => {
  const t = Translate('DeviceListPage');
  const useQuery = useCallback(() => new URLSearchParams(useLocation().search), []);
  const [paginationTable, setPaginationTable] = useState<TablePaginationConfig>({
    ...PAGINATION_TABLE,
    pageSize: handlePageSize({ place: 'device_detail' }),
  });

  const query = useQuery();

  const columns: ColumnsType<DeviceType & CustomerType & FootprintType & LocationType & DeviceReportType> = [
    {
      title: 'Customer',
      dataIndex: 'customer_name',
      key: 'customer_name',
    },
    {
      title: 'Location',
      dataIndex: 'location_name',
      key: 'location_name',
    },
    {
      title: 'Place',
      dataIndex: 'partner_footprint_id',
      key: 'partner_footprint_id',
      sorter: (a: any, b: any) => (a.customer_name > b.customer_name ? 1 : -1),
    },
    {
      title: 'Device Name',
      dataIndex: 'device_number',
      key: 'device_number',
    },
    {
      title: 'Last Report',
      dataIndex: 'report_datetime',
      key: 'report_datetime',
      defaultSortOrder: 'descend',
      sorter: (a: any, b: any) => {
        const time1 = a.report_datetime ? DateTime.fromISO(a.report_datetime).toMillis() : 0;
        const time2 = b.report_datetime ? DateTime.fromISO(b.report_datetime).toMillis() : 0;
        return (time1 > time2 ? 1 : -1);
      },
      render: (value: string) => (value ? DateTime.fromISO(value).toUTC().toFormat('y-LL-dd hh:mm:ss a') : DEFAULT_VALUE),
    },
    {
      title: 'Ver',
      dataIndex: 'appv',
      key: 'appv',
      render: (value: string) => (value || DEFAULT_VALUE),
    },
    {
      title: 'Distance',
      dataIndex: 'depth',
      key: 'depth',
      sorter: (a: any, b: any) => (a.depth > b.depth ? 1 : -1),
      render: (value: number) => (value >= 0 ? `${Math.round((value! / INCHES))}` : DEFAULT_VALUE),
    },
    {
      title: 'Fill %',
      dataIndex: 'fill_percent',
      key: 'fill_percent',
      sorter: (a: any, b: any) => (a.fill_percent > b.fill_percent ? 1 : -1),
      render: (value: number) => (value >= 0 ? `${Math.round(value!)}%` : DEFAULT_VALUE),
    },
    {
      title: 'Battery',
      dataIndex: 'battery',
      key: 'battery',
      render: (value: number) => (value ? <Battery value={value}/> : DEFAULT_VALUE),
    },
    {
      title: 'Signal',
      dataIndex: 'signal_rsrp',
      key: 'signal_rsrp',
      render: (value: number) => (value ? <Signal rssi={value}/> : DEFAULT_VALUE),
    },
    {
      title: 'Device serial number',
      dataIndex: 'device_serial_number',
      key: 'device_serial_number',
    },
    {
      title: 'Service',
      dataIndex: 'service_button',
      key: 'service_button',
      width: 100,
      render: (value: boolean) => (value ? 'serviced' : ''),
    },
    {
      title: 'Add Service',
    },
    {
      title: 'Remove Service',
    },
    {
      title: 'Hide Report',
    },
    {
      title: 'Restore Report',
    },
  ];

  const [spin, setSpin] = useState(false);
  const [dataTable, setDataTable] = useState<(DeviceType & CustomerType & FootprintType & LocationType & DeviceReportType)[]>([]);

  const getDataDB = useCallback(async () => {
    const device = query.get('device');
    const rowlimit = query.get('rowlimit');

    const dataDB = (await axios.get<(DeviceType & CustomerType & FootprintType & LocationType & DeviceReportType)[]>(`${API.URL_AURORA_DB}/device_detail_list?device=${device}&rowlimit=${rowlimit}`)).data;

    const newDataDB = dataDB.map((elem) => {
      const newElem = elem;
      newElem.key = elem.device_report_id;
      return newElem;
    });

    setDataTable(newDataDB);

    setSpin(false);
  }, []);

  useEffect(() => {
    setSpin(true);
    getDataDB();
  }, []);

  const handleTableChange = useCallback((pagination) => {
    handlePageSize({ place: 'device_detail', pageSize: pagination.pageSize });
    setPaginationTable(pagination);
  }, []);

  return (
    <>
      <Space direction={'horizontal'} align={'center'} style={{ justifyContent: 'space-between', width: '100%' }}>
        <Title level={3}>{t.k1('Device')}</Title>
      </Space>
      <Spin spinning={spin} tip={'please wait ...'}>
        <Table<DeviceType & CustomerType & FootprintType & LocationType & DeviceReportType>
          columns={columns}
          dataSource={dataTable}
          pagination={paginationTable}
          onChange={handleTableChange}
        />
      </Spin>
    </>
  );
};
