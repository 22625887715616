import CheckOutlined from '@ant-design/icons/lib/icons/CheckOutlined';
import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import CloseOutlined from '@ant-design/icons/lib/icons/CloseOutlined';
import {
  Button, Checkbox, Input, notification, Tag,
} from 'antd';
import Empty from 'antd/es/empty';
import { useLocation } from 'react-router';
import React, {
  ReactNode, useCallback, useEffect, useState,
} from 'react';
import '../styles/LocationWorkNotesPage.scss';
import { DateTime } from 'luxon';
import { CheckCircleFilled } from '@ant-design/icons';
import { NotificationPlacement } from 'antd/es/notification';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store';
import { addSpinnerItem, removeSpinnerItem } from '../../../store/slices/spinnerSlice';
import { WorkNoteType } from '../../../../../common/entityTypes';
import {
  addNote, deleteNote, editWorkNote, fetchWorkNoteById, updateNote,
} from '../../../store/slices/workNoteSlice';
import { dateNowForDB } from '../../../common';

export const LocationDetailWorkNotesPage = () => {
  const useQuery = useCallback(() => new URLSearchParams(useLocation().search), []);
  const query = useQuery();
  const locationId = query.get('lid') || '';
  const dispatch = useDispatch<AppDispatch>();
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement: NotificationPlacement, message: string, desc: string, icon: ReactNode) => {
    api.info({
      message,
      description: desc,
      placement,
      icon,
    });
  };

  const [filterWithCompleted, setFilterWithCompleted] = useState(false);
  const [noteContent, setNoteContent] = useState('');
  const [noteContentToEdit, setNoteContentToEdit] = useState('');
  const [filteredWorkNotes, setFilteredWorkNotes] = useState<WorkNoteType[]>([]);

  const workNoteList = useSelector<RootState, WorkNoteType[]>((store) => store.workNote.workNoteList);

  const handleChangeFilterCheckbox = () => {
    setFilterWithCompleted(!filterWithCompleted);
  };

  const handleFilterNotes = (newNotes: any) => {
    if (filterWithCompleted) {
      setFilteredWorkNotes(newNotes);
    } else {
      setFilteredWorkNotes(newNotes.filter((note: any) => !note.completed));
    }
  };

  const handleFetchWorkNotes = async () => {
    await dispatch(fetchWorkNoteById(`location_id=${locationId}`));
  };

  useEffect(() => {
    handleFilterNotes(workNoteList);
  }, [workNoteList, filterWithCompleted]);

  useEffect(() => {
    (async () => {
      dispatch(addSpinnerItem('location_notes_page'));
      await handleFetchWorkNotes();
      dispatch(removeSpinnerItem('location_notes_page'));
    })();
  }, []);

  const handleAddNewNote = async () => {
    dispatch(addSpinnerItem('location_notes_page'));
    await dispatch(addNote({
      note: noteContent,
      effective_from_datetime: dateNowForDB(),
      location_id: Number(locationId),
      route_schedule_id: 1,
    }));
    setNoteContent('');
    await handleFetchWorkNotes();
    const successMsg = 'New note added successfully';
    const successDesc = '';
    const successIcon = <CheckCircleFilled style={{ color: 'green' }}/>;
    openNotification('topRight', successMsg, successDesc, successIcon);
    dispatch(removeSpinnerItem('location_notes_page'));
  };

  const handleDeleteNote = async (id: number) => {
    dispatch(addSpinnerItem('location_notes_page'));
    await dispatch(deleteNote(id));
    await handleFetchWorkNotes();
    const successMsg = 'Note deleted successfully';
    const successDesc = '';
    const successIcon = <CheckCircleFilled style={{ color: 'green' }}/>;
    openNotification('topRight', successMsg, successDesc, successIcon);
    dispatch(removeSpinnerItem('location_notes_page'));
  };

  const handleEditNote = (id: number) => {
    dispatch(editWorkNote({ id, edit: true }));
    setNoteContentToEdit(workNoteList.find((note) => note.work_note_id === id)!.note);
  };

  const handleCloseEditNote = (id: number) => {
    dispatch(editWorkNote({ id, edit: false }));
    setNoteContentToEdit('');
  };

  const handleChangeEditNote = (event: any) => {
    setNoteContentToEdit(event.target.value);
  };

  const handleCheckNode = async (id: number) => {
    dispatch(addSpinnerItem('location_notes_page'));
    const workNote = workNoteList.find((note) => note.work_note_id === id)!;
    await dispatch(updateNote({
      workNoteId: id,
      payload: {
        completed: !workNote.completed,
        effective_to_datetime: dateNowForDB(),
      },
    }));
    dispatch(editWorkNote({ id, edit: false }));
    setNoteContentToEdit('');
    await handleFetchWorkNotes();
    const successMsg = 'Note edited successfully';
    const successDesc = '';
    const successIcon = <CheckCircleFilled style={{ color: 'green' }}/>;
    openNotification('topRight', successMsg, successDesc, successIcon);
    dispatch(removeSpinnerItem('location_notes_page'));
  };

  const handleCompleteEditNote = async (id: number) => {
    dispatch(addSpinnerItem('location_notes_page'));
    await dispatch(updateNote({
      workNoteId: id,
      payload: {
        note: noteContentToEdit,
      },
    }));
    dispatch(editWorkNote({ id, edit: false }));
    setNoteContentToEdit('');
    await handleFetchWorkNotes();
    const successMsg = 'Note edited successfully';
    const successDesc = '';
    const successIcon = <CheckCircleFilled style={{ color: 'green' }}/>;
    openNotification('topRight', successMsg, successDesc, successIcon);
    dispatch(removeSpinnerItem('location_notes_page'));
  };

  return (
    <div className="notes">
      {contextHolder}
      <Checkbox checked={filterWithCompleted} onChange={handleChangeFilterCheckbox}>
        Show completed notes
      </Checkbox>
      <div className="notes__create-box">
        <Input
          value={noteContent}
          onChange={(event) => setNoteContent(event.target.value)}
          placeholder={'Write new note'}/>
        <Button disabled={!noteContent} onClick={handleAddNewNote}>Add work note</Button>
      </div>
      <div className="notes__list">
        {filteredWorkNotes.length ? filteredWorkNotes.map((note: any) => (
          <div key={note.work_note_id} className="notes__item">
            <div className="notes__item-box">
              {note.edit
                ? <Input value={noteContentToEdit} onChange={handleChangeEditNote}/>
                : <Checkbox checked={note.completed} onChange={() => handleCheckNode(note.work_note_id)}>
                  <p>{note.note}</p>
                </Checkbox>}
            </div>
            <div className="notes__item-box">
              <div className="notes__item-sub-box">
                <p>
                  <Tag color="green">
                    {DateTime.fromJSDate(new Date(`${note.effective_from_datetime}`))
                      .toFormat('y-LL-dd hh:mm:ss a')
                      .toString()}
                  </Tag>
                </p>
                {note.effective_to_datetime && <p>
                  <Tag color="orange">
                    {DateTime.fromJSDate(new Date(`${note.effective_to_datetime}`))
                      .toFormat('y-LL-dd hh:mm:ss a')
                      .toString()}
                  </Tag>
                </p>}
              </div>
              {!note.completed
                && <div className="notes__item-sub-box">
                  {note.edit
                    ? <>
                      <Button onClick={() => handleCompleteEditNote(note.work_note_id)}>
                        <CheckOutlined/>
                      </Button>
                      <Button onClick={() => handleCloseEditNote(note.work_note_id)}>
                        <CloseOutlined/>
                      </Button>
                    </>
                    : <>
                      <Button onClick={() => handleEditNote(note.work_note_id)}>
                        <EditOutlined/>
                      </Button>
                      <Button onClick={() => handleDeleteNote(note.work_note_id)}>
                        <DeleteOutlined/>
                      </Button>
                    </>
                  }
                </div>
              }
            </div>
          </div>
        )) : <Empty/>
        }
      </div>
    </div>
  );
};
