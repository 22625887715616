import * as React from 'react';
import {
  Avatar, Typography,
} from 'antd';
import {
  UserOutlined,
} from '@ant-design/icons/lib';
import styled from 'styled-components';
import { useDataCommon } from '../DataCommonProvider';

const { Text } = Typography;

type AvatarUserProps = {
  showLogin?: boolean
};

const SpanStyle = styled(Text)`
  &:hover {
    color: var(--primary-color);
  }
`;

export const AvatarUser: React.FC<AvatarUserProps> = ({ showLogin }) => {
  const dataCommon = useDataCommon();

  return (
    <>
        <Avatar style={{ backgroundColor: '#87d068', marginRight: '10px' }} icon={<UserOutlined />} />
        {
            showLogin && (<SpanStyle>{dataCommon.username}</SpanStyle>)
        }
    </>
  );
};
