import {
  Button, Form, Input, notification, Select, Spin,
} from 'antd';
import React, { ReactNode, useEffect } from 'react';
import { NotificationPlacement } from 'antd/es/notification';
import { CheckCircleFilled } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { addCustomer } from '../../../store/slices/customerSlice';
import { AppDispatch, RootState } from '../../../store';
import { RegionType } from '../../../../../common/entityTypes';
import { fetchRegions } from '../../../store/slices/regionSlice';
import { addSpinnerItem, removeSpinnerItem } from '../../../store/slices/spinnerSlice';
import './AddCustomer.scss';

const { Option } = Select;

export const AddCustomer = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement: NotificationPlacement, message: string, desc: string, icon: ReactNode) => {
    api.info({
      message,
      description: desc,
      placement,
      icon,
    });
  };

  const regionList = useSelector<RootState, RegionType[]>((store) => store.region.regionList);
  const spinner = useSelector<RootState, boolean>((store) => store.spinner.spinner);

  useEffect(() => {
    (async () => {
      dispatch(addSpinnerItem('add_customer_detail'));
      await dispatch(fetchRegions());
      dispatch(removeSpinnerItem('add_customer_detail'));
    })();
  }, []);

  const onFinish = async (values: any) => {
    console.log('values', values);
    dispatch(addSpinnerItem('add_customer_detail'));
    await dispatch(addCustomer({
      ...values,
      deleted: false,
    }));
    const successMsg = 'Customer added successfully';
    const successDesc = '';
    const successIcon = <CheckCircleFilled style={{ color: 'green' }}/>;
    openNotification('topRight', successMsg, successDesc, successIcon);
    dispatch(removeSpinnerItem('add_customer_detail'));
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Spin spinning={spinner} tip={'please wait ...'}>

      {contextHolder}
      <Form
        form={form}
        name="addCustomer"
        labelCol={{ span: 5 }}
        labelAlign={'left'}
        wrapperCol={{ span: 8 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className={'add-customer__form'}
      >
        <Form.Item
          label="Region"
          name="region_id"
          rules={[{ required: true, message: 'Please input region!' }]}
        >
          <Select
            allowClear
            placeholder={'Select region'}
            value={form.getFieldValue('region_id')}
          >
            {regionList.map((region) => (
              <Option
                key={region.region_id}
                value={region.region_id!}
              >
                {region.region_name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Name"
          name="customer_name"
          rules={[{ required: true, message: 'Please input customer name!' }]}
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label="Address 1"
          name="address1"
          rules={[{ required: true, message: 'Please input customer Address 1!' }]}
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label="Address 2"
          name="address2"
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label="City"
          name="city"
          rules={[{ required: true, message: 'Please input customer city!' }]}
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label="State Province"
          name="state_province"
          rules={[{ required: true, message: 'Please input customer State Province!' }]}
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label="Zip"
          name="zip"
          rules={[{ required: true, message: 'Please input customer Zip!' }]}
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label="Country"
          name="country"
          rules={[{ required: true, message: 'Please input customer Country!' }]}
        >
          <Input/>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};
